import type { HTMLAttributes, MouseEvent } from 'react'
import { Container, Keyword } from './HotKeyword.css'

interface HotKeywordProps
  extends Omit<HTMLAttributes<HTMLLIElement>, 'onClick'> {
  keyword: string
  index: number
  testGroup: string
  queryInfo: string
  onClick: (
    e: MouseEvent<HTMLLIElement>,
    info: {
      keyword: string
      index: number
      testGroup: string
      queryInfo: string
    }
  ) => void
}

const HotKeyword = (props: HotKeywordProps) => {
  const onClick = (e: MouseEvent<HTMLLIElement>) => {
    props.onClick(e, {
      keyword: props.keyword,
      index: props.index,
      testGroup: props.testGroup,
      queryInfo: props.queryInfo,
    })
  }

  return (
    <li
      role="button"
      className={`${Container} ${props.className ?? ''}`}
      onClick={onClick}
    >
      <span className={Keyword}>{props.keyword}</span>
    </li>
  )
}

export default HotKeyword
