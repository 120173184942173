import { useCallback, useRef, useState } from 'react'

const useTouchDraggable = ({
  elRef,
  initialPosition,
}: {
  elRef: { current: HTMLElement | null }
  initialPosition?: { top: number; left: number }
}) => {
  const startPositionRef = useRef({
    top: initialPosition?.top ?? 0,
    left: initialPosition?.left ?? 0,
  })

  const [position, setPosition] = useState({
    top: startPositionRef.current.top,
    left: startPositionRef.current.left,
  })

  const onTouchStart = useCallback((e) => {
    startPositionRef.current = {
      top: e.changedTouches[0].pageY,
      left: e.changedTouches[0].pageX,
    }
  }, [])

  const onTouchMove = useCallback((e) => {
    const delta = {
      top: e.changedTouches[0].pageY - startPositionRef.current.top,
      left: e.changedTouches[0].pageX - startPositionRef.current.left,
    }

    elRef.current &&
      setPosition({
        top: elRef.current.offsetTop + delta.top,
        left: elRef.current.offsetLeft + delta.left,
      })

    startPositionRef.current = {
      top: e.changedTouches[0].pageY,
      left: e.changedTouches[0].pageX,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { position, onTouchStart, onTouchMove }
}
export default useTouchDraggable
