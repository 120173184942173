import type {
  ReactNode,
  PropsWithoutRef,
  ForwardedRef,
  ElementType,
} from 'react'
import { forwardRef } from 'react'
import classNames from 'classnames'
import * as css from './HideAnimation.css'

export type HideAnimationAs = 'li' | 'div'

export type HideAnimationElement<T extends HideAnimationAs> = T extends 'li'
  ? HTMLLIElement
  : HTMLDivElement

export type HideAnimationProps<T extends HideAnimationAs> =
  T extends HideAnimationAs
    ? PropsWithoutRef<
        JSX.IntrinsicElements[T] & {
          as: T
          className?: string
          children?: ReactNode
          isHide?: boolean
          onAnimationEnd?: () => void
        }
      >
    : never
const HideAnimation = forwardRef(
  <T extends HideAnimationAs>(
    {
      as,
      className,
      children,
      isHide,
      onAnimationEnd,
      ...props
    }: HideAnimationProps<T>,
    ref: ForwardedRef<HideAnimationElement<T>>
  ) => {
    const Component = as as ElementType
    return (
      <Component
        className={classNames(css.root, className, { [css.hide]: isHide })}
        onAnimationEnd={onAnimationEnd}
        {...props}
      >
        {children}
      </Component>
    )
  }
)
export default HideAnimation
