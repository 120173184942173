import { LOG_STATUS } from './constants'

export const clearPrevLogStatus = (el: HTMLElement) => {
  if (!el) return
  delete el.dataset['prevLogStatus']
}

/**
 * check whether previous log status is Observed
 * it is used to decide whether to trigger impression logging
 */
export const hasCameBackFromDisabledStatus = (el: HTMLElement) => {
  if (!el) return false
  const prevLogStatus = el.dataset['prevLogStatus']
  return prevLogStatus && prevLogStatus === LOG_STATUS.DISABLE ? true : false
}

/**
 * delete log status except for 'observed' status,
 * and update previous log status to 'disable'
 */
const clearPrevLogStatusExceptForObservedStatus = (el: HTMLElement) => {
  if (!el) return
  const prevLogStatus = el.dataset['prevLogStatus']
  if (prevLogStatus === LOG_STATUS.OBSERVED) {
    el.dataset['prevLogStatus'] = LOG_STATUS.DISABLE
  } else {
    clearPrevLogStatus(el)
  }
}
/**
 * enable impression logging
 * @param el
 */
export const enableImpression = (el: HTMLElement) => {
  if (!el) return
  const prevLogStatus = el.dataset['prevLogStatus']
  if (prevLogStatus !== LOG_STATUS.DISABLE && prevLogStatus) {
    el.dataset['logStatus'] = prevLogStatus
  }
  clearPrevLogStatusExceptForObservedStatus(el)
}
