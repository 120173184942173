export const HOME_TABS = {
  UNKNOWN: {
    ID: 'UNKNOWN',
    LOWERCASE_ID: 'unknown',
    LABEL: '',
  },
  AGGREGATED: {
    ID: 'ALL',
    LOWERCASE_ID: 'all',
    LABEL: '통합',
  },
  FLEA_MARKET: {
    ID: 'FLEA_MARKET',
    LOWERCASE_ID: 'flea_market',
    LABEL: '중고거래',
  },
  COMMUNITY: {
    ID: 'COMMUNITY',
    LOWERCASE_ID: 'community',
    LABEL: '동네생활',
  },
  BUSINESS: {
    ID: 'BUSINESS',
    LOWERCASE_ID: 'business',
    LABEL: '동네홍보',
  },
  BIZ_ACCOUNT: {
    ID: 'BIZ_ACCOUNT',
    LOWERCASE_ID: 'biz_account',
    LABEL: '동네업체',
  },
  USERS: {
    ID: 'USERS',
    LOWERCASE_ID: 'users',
    LABEL: '이웃',
  },
} as const

export const HOME_TAB_KEYS = Object.values(HOME_TABS).map((item) => item.ID)
