import type { StateCreatorWithDevtools } from '../../__development/zustand'

export const PLACE_FILTER_SORTS = {
  RELEVANT: {
    ID: 'RELEVANT',
    LABEL: '정확도순',
  },
  DISTANCE: {
    ID: 'DISTANCE',
    LABEL: '가까운순',
  },
} as const

export type PlaceSortIdType =
  (typeof PLACE_FILTER_SORTS)[keyof typeof PLACE_FILTER_SORTS]['ID']

interface FilterSortType {
  sortId: PlaceSortIdType
}

export type PlaceFilterSliceType = {
  placeFilter: {
    sortId: PlaceSortIdType
    changeSort: (range: FilterSortType) => void
  }
}

export const placeFilterSlice: StateCreatorWithDevtools<
  PlaceFilterSliceType
> = (set) => ({
  placeFilter: {
    sortId: PLACE_FILTER_SORTS.RELEVANT.ID,

    changeSort: (range: FilterSortType) =>
      set(
        (state) => ({
          placeFilter: {
            ...state.placeFilter,
            sortId: range.sortId,
          },
        }),
        false,
        'placeFilter/changeSort'
      ),
  },
})
