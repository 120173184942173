import type { HTMLAttributes } from 'react'
import { Bone } from './Skeleton.css'

interface SkeletonProps extends HTMLAttributes<HTMLDivElement> {
  width: string
  height: string
  margin?: {
    left?: string
    right?: string
    top?: string
    bottom?: string
  }
  border?: {
    radius?: string
  }
  display?: string
  flex?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme?: any
}

const Skeleton = ({
  width,
  height,
  margin,
  border,
  display,
  flex,
  theme,
  children,
  ...props
}: SkeletonProps) => {
  return (
    <div
      {...props}
      className={Bone}
      style={{
        width: width,
        height: height,
        borderRadius: border?.radius || '.25rem',
        display: display || 'block',
        marginBottom: margin?.bottom || 0,
        marginTop: margin?.top || 0,
        marginLeft: margin?.left || 0,
        marginRight: margin?.right || 0,
        flex: flex || 'none',
        verticalAlign: display === 'inline-block' ? 'middle' : 'initial',
      }}
    >
      {children}
    </div>
  )
}

export default Skeleton
