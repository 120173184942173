import type { GlobalState } from './GlobalStore'
import type { StateCreator } from 'zustand'
import { placeMapSlice } from '../../place/stores/placeMapSlice'
import { lastOpenedSchemeTargetSlice } from './LastOpenedSchemeTargetSlice'
import {
  experimentSlice,
  type ExperimentSliceType,
} from '../../experiment/stores/ExperimentSlice'
import { snackbarSlice } from '../components/snackbar/snackbarSlice'

export type GlobalStoreActionTypes = {
  // 동네업체 지도
  placeMapChangeVisibility: (isVisible: boolean) => void
  // 마지막 방문한 스킴 타깃
  resetLastOpenedSchemeTarget: () => void
  setLastOpenedSchemeTargetState: (args: { schemeTarget: string }) => void
  // 실험정보
  setExperimentState: ExperimentSliceType['experiment']['setState']
  // 스낵바
  showingSnackbar: () => void
  closedSnackbar: () => void
}

export const globalStoreActions: StateCreator<
  GlobalState,
  [],
  [],
  GlobalStoreActionTypes
> = (set, get, api) => ({
  placeMapChangeVisibility: (isVisible: boolean) => {
    placeMapSlice(set, get, api).placeMap.changeMapVisibility(isVisible)
  },
  // MARK: 마지막 방문한 스킴 타깃
  resetLastOpenedSchemeTarget: () => {
    lastOpenedSchemeTargetSlice(
      set,
      get,
      api
    ).lastOpenedSchemeTargetSlice.reset()
  },
  setLastOpenedSchemeTargetState: ({
    schemeTarget,
  }: {
    schemeTarget: string
  }) => {
    lastOpenedSchemeTargetSlice(
      set,
      get,
      api
    ).lastOpenedSchemeTargetSlice.setLastOpenedSchemeTargetState({
      schemeTarget,
    })
  },
  // MARK: 실험정보
  setExperimentState: (newExperimentState) => {
    experimentSlice()(set, get, api).experiment.setState(newExperimentState)
  },
  // MARK: 스낵바
  showingSnackbar: () => {
    snackbarSlice(set, get, api).snackbar.showing()
  },
  closedSnackbar: () => {
    snackbarSlice(set, get, api).snackbar.closed()
  },
})
