import {
  UnauthorizedError,
  RetryError,
  InternalServerError,
  AuthTokenExpiredError,
} from '@daangn/plantae-plugin-auth'

type AuthorizationError =
  | UnauthorizedError
  | RetryError
  | InternalServerError
  | AuthTokenExpiredError

/**
 * plantae-plugin-auth에서 발생하는 에러
 *
 * 참고: https://github.com/daangn/webview-network/blob/main/packages/plantae-plugin-auth/src/types/errors.ts
 */
export const isAuthorizationError = (
  error: unknown
): error is AuthorizationError => {
  /**
   * 요청에 Authorization 헤더가 존재하지 않으나, 서버에서 401을 반환한 경우
   */
  if (error instanceof UnauthorizedError) {
    return true
  }

  /**
   * 갱신한 Access Token으로 요청을 보냈으나 실패한 경우
   */
  if (error instanceof RetryError) {
    return true
  }

  /**
   * Auth Token 인증 도중, 서버에서 에러가 발생한 경우
   */
  if (error instanceof InternalServerError) {
    return true
  }

  /**
   * Auth Token이 만료된 경우
   */
  if (error instanceof AuthTokenExpiredError) {
    return true
  }
  return false
}
