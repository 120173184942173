import type { EXPERIMENT_LIVE_KEYS } from '..'
import type { StateCreatorWithDevtools } from '../../__development/zustand'
import { arrayDeepCopy } from '../../_app/utils/helpers'
import type { ExperimentSegmentStatusType } from '../constants/experiment'

type LiveExperimentValueTypes =
  (typeof EXPERIMENT_LIVE_KEYS)[keyof typeof EXPERIMENT_LIVE_KEYS]

export interface ExperimentParams {
  key: LiveExperimentValueTypes
}

export interface ExperimentSegmentType {
  segmentKey: string
  segmentName: string
  segmentType?: ExperimentSegmentStatusType
}

export interface ExperimentStateType {
  experimentSegments: ExperimentSegmentType[]
  headerSegmentKey: string
  headerSegmentValue: string
}

export interface ExperimentType {
  state: ExperimentStateType
}

export interface ExperimentSliceType {
  experiment: ExperimentType & {
    init: () => void
    setState: (newExperimentState: ExperimentStateType) => void
  }
}

const DEFAULT_STATE: ExperimentStateType = {
  experimentSegments: [],
  headerSegmentKey: '',
  headerSegmentValue: '',
}

export const experimentSlice =
  (
    injectedInitState?: ExperimentStateType
  ): StateCreatorWithDevtools<ExperimentSliceType> =>
  (set) => ({
    experiment: {
      state: injectedInitState ?? DEFAULT_STATE,

      init: () => {
        set((slice) => ({
          experiment: {
            ...slice.experiment,
            state: DEFAULT_STATE,
          },
        }))
      },

      setState: (newExperimentState) => {
        set((slice) => {
          const { experimentSegments, headerSegmentKey, headerSegmentValue } =
            newExperimentState

          const deepCopiedExperimentSegments = arrayDeepCopy(experimentSegments)

          return {
            experiment: {
              ...slice.experiment,
              state: {
                experimentSegments: deepCopiedExperimentSegments,
                headerSegmentKey: headerSegmentKey,
                headerSegmentValue: headerSegmentValue,
              },
            },
          }
        })
      },
    },
  })
