import 'src/packages/business/CardBizPost.css.ts.vanilla.css?source=#H4sIAAAAAAAAA71W32+bMBB+71+BJk1aH4xC0qYN0V7Wp77tfZoqYwxcazCyTdJ02v8+Y3D4ZVqySVMUAfb58913353tP2ARf4O371yqJwWK0QdeKAwFFU9Pz1kQML7yfl15XgyyZPgUegmjr3s9gBmkBQJFcxl6hBaKiv3V7yu/DyhoWjF8RgrcSPUz9Fb6hyvF65HnSipITohoXzRyhz+zrcbQlkjCG9VA/t2toHk9GmHykgpeFXHoHbD4gpCkNEaSYEY1OOMCpQKfULBaXRt7LmIqkMAxVLJGCu7PWDkWKRRIQJqpemptJ44Qqyz0Ar+zzWhjFfibdmxEDOFVyQvLy9rwUnIJCngRejiSnFWK1lCMJvV29aviZfs28XMTGHdqEu373kX15cS20a39+dg8j5eYgDrVnuwuzwbNcaGAtAkpBWiqTyYfZmRorHBn2n4cM+31tYPjkZA3bvk5NhnrY2f0McLXbqbTarkxm0wl3fK49Sci2boYQg6vRjzhUkugW3E9AmjoJ/zQ5LE30wntdvW5/k/lHdjE6tUJ48fQyyCOabEf6FRQphNwoG5JupU/x9rUyM7dGkYX8cejZ0oUSkD1I7/Es/OmP6QgXz99+jnUTMELOlmY61Dsuu18i0MxCO2dYU6nsMqLrvkF88TmOitt8CunxnWp2t3vGvH1q2/SwJou4juaBDrS6AUUirjx2X4ynShEGM71unV/QtshLsD0Ec21liZmpk/RV4U65VDGoJQg5/R01PlBkaD4JfTMA2FmcA4gIQJmGot5Z3Rhvd656tWcbpap+2GeFoQe/M/Qeym0pWh8OTffm6Zq/7Z3lYInwOijsxh3/YJbOY61/tikurbrD09MFxX/ciu4LLhZW2uC3TW8kJBlPciy9I7rZ3cuaEUEl3UHsUsjs2SBSLbtBWjYOoLN3D1i5rIwm/GheNsjp7tJuMkYBxMyLBUiGbDYxDXa7eP1CQgNwBOkTiXtQzSXrEZnbhBpUYhbHP3m+h7OI+kcit1Q79zRzhLcbRwa7A0uzE93CCzQyI2rkcgqemj8nFTagOH21LGJ/gNn9PKhdwwAAA==';
export var caption = 'CardBizPost_caption__jh11lob';
export var captionIcon = 'CardBizPost_captionIcon__jh11lod';
export var captions = 'CardBizPost_captions__jh11loc';
export var container = 'CardBizPost_container__jh11lo3';
export var content = 'CardBizPost_content__jh11lo7';
export var coupon = 'CardBizPost_coupon__jh11lo2';
export var image = 'CardBizPost_image__jh11lo5';
export var imageContainer = 'CardBizPost_imageContainer__jh11lo4';
export var main = 'CardBizPost_main__jh11lo6';
export var profileImage = 'CardBizPost_profileImage__jh11loa';
export var profileImageContainer = 'CardBizPost_profileImageContainer__jh11lo9';
export var regular = 'CardBizPost_regular__jh11lo1';
export var subContentContainer = 'CardBizPost_subContentContainer__jh11loe';
export var title = 'CardBizPost_title__jh11lo8';
export var titleContainer = 'CardBizPost_titleContainer__jh11lo0';