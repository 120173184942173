import type { ReactNode, RefObject } from 'react'
import { createContext, useContext, useState, useRef, useEffect } from 'react'
import { v4 } from 'uuid'
import { setReferrerBridgePersistedState } from '../../__deprecated/stores/deprecatedPersistentStore'

type State = {
  searchFunnelId: string
  isMounted: RefObject<boolean>
}
const SearchFunnelIdStateContext = createContext<State | null>(null)
const SearchFunnelIdUpdaterContext = createContext<
  ((action: 'update' | 'restore', newFunnelId: string) => void) | null
>(null)

interface SearchFunnelIdProviderProps {
  children: ReactNode
}

export const SearchFunnelIdProvider = (props: SearchFunnelIdProviderProps) => {
  const [funnelId, setFunnelId] = useState<string>(v4())
  const isMounted = useRef<boolean>(false)

  /**
   * @deprecated
   * PageHomeSearch로 전부 마이그레이션 이후
   * action 유형 삭제
   */
  const handleUpdater = (action: 'update' | 'restore', newFunnelId: string) => {
    if (!newFunnelId) {
      return
    }

    setFunnelId(newFunnelId)
    setReferrerBridgePersistedState({
      searchFunnelId: action === 'update' ? newFunnelId : '',
    })
  }

  useEffect(() => {
    isMounted.current = true
  }, [])

  return (
    <SearchFunnelIdStateContext.Provider
      value={{ searchFunnelId: funnelId, isMounted: isMounted }}
    >
      <SearchFunnelIdUpdaterContext.Provider value={handleUpdater}>
        {props.children}
      </SearchFunnelIdUpdaterContext.Provider>
    </SearchFunnelIdStateContext.Provider>
  )
}

export function useSearchFunnelId() {
  const state = useContext(SearchFunnelIdStateContext)
  const updater = useContext(SearchFunnelIdUpdaterContext)

  if (!state) {
    throw new Error('not found SearchFunnelIdStateContext')
  }

  if (!updater) {
    throw new Error('not found SearchFunnelIdUpdaterContext')
  }

  return {
    searchFunnelId: state.searchFunnelId,
    isMountedRef: state.isMounted,
    /**
     * @deprecated
     * PageHomeSearch로 전부 마이그레이션 이후
     */
    updateSearchFunnelId: updater,
  }
}
