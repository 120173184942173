import { useMemo } from 'react'
import { TagGroupEllipsis } from '../tag-group'
import { TagEllipsis } from '../tag'
import { Highlight } from '../highlight'
import {
  ADAPTIVE_IMAGE_FORMAT,
  ADAPTIVE_IMAGE_RESIZABLE_OPTION,
  AdaptiveImage,
} from '../adaptive-image'
import * as css from './CardBizPost.css'
import {
  IconHeartFill,
  IconHorizline2VerticalChatbubbleRectangularRightFill,
} from '@daangn/react-monochrome-icon'

type HighlightType = {
  readonly title: string | null
  readonly content: string | null
}

interface CardBizPostProps {
  thumbnail?: string | null
  bizAccountThumbnail?: string | null
  fallbackThumbnail: string
  title: string
  content: string | null
  bizAccountName: string | null
  hasCoupon: boolean
  regionName: string
  createdAt: string
  commentCount: number | null
  bookmarkCount: number | null
  isBizAccount: boolean
  isRegular: boolean | null
  highlight: HighlightType | null
}
const CardBizPost = (props: CardBizPostProps) => {
  const a11yLabel = useMemo(() => {
    return `동네홍보 ${props.title} ${props.content} ${props.bizAccountName} ${
      props.hasCoupon ? '쿠폰 제공해요 ' : ''
    }${props.regionName} ${props.createdAt} 댓글${
      props.commentCount ?? 0
    } 관심${props.bookmarkCount ?? 0}`
  }, [
    props.title,
    props.content,
    props.bizAccountName,
    props.hasCoupon,
    props.regionName,
    props.createdAt,
    props.commentCount,
    props.bookmarkCount,
  ])

  return (
    <div className={css.container} aria-label={a11yLabel}>
      <div className={css.imageContainer}>
        <AdaptiveImage
          className={css.image}
          src={props.thumbnail || props.fallbackThumbnail}
          fallbackSrc={props.fallbackThumbnail}
          options={[
            {
              size: '300x300',
              quality: 82,
              type: ADAPTIVE_IMAGE_RESIZABLE_OPTION.CROP,
              format: ADAPTIVE_IMAGE_FORMAT.WEBP,
            },
          ]}
        />
        {props.isBizAccount && props.hasCoupon && (
          <div className={css.coupon}>쿠폰</div>
        )}
      </div>
      <div className={css.main}>
        <div className={css.titleContainer}>
          {props.isRegular ? <div className={css.regular}>단골</div> : null}
          <Highlight
            className={css.title}
            dangerouslySetInnerHTML={{
              __html: props.highlight?.title || '',
            }}
          />
        </div>
        {props.highlight?.content && (
          <div className={css.content}>
            <Highlight
              dangerouslySetInnerHTML={{
                __html: props.highlight.content,
              }}
            />
          </div>
        )}
        <div className={css.subContentContainer}>
          <TagGroupEllipsis small>
            {props.isBizAccount ? (
              <TagEllipsis
                small
                icon={
                  <div className={css.profileImageContainer}>
                    <AdaptiveImage
                      className={css.profileImage}
                      src={props.bizAccountThumbnail || props.fallbackThumbnail}
                      fallbackSrc={props.fallbackThumbnail}
                      options={[
                        {
                          size: '80x80',
                          quality: 82,
                          type: ADAPTIVE_IMAGE_RESIZABLE_OPTION.CROP,
                          format: ADAPTIVE_IMAGE_FORMAT.WEBP,
                        },
                      ]}
                    />
                  </div>
                }
              >
                {props.bizAccountName}
              </TagEllipsis>
            ) : null}
            <TagGroupEllipsis small subGroup>
              <TagEllipsis small>{props.regionName}</TagEllipsis>
              <TagEllipsis small>{props.createdAt}</TagEllipsis>
            </TagGroupEllipsis>
          </TagGroupEllipsis>
        </div>
        <div className={css.captions}>
          {props.commentCount && props.commentCount > 0 ? (
            <div className={css.caption}>
              <div className={css.captionIcon}>
                <IconHorizline2VerticalChatbubbleRectangularRightFill
                  size={15}
                />
              </div>
              {props.commentCount}
            </div>
          ) : null}
          {props.bookmarkCount && props.bookmarkCount > 0 ? (
            <div className={css.caption}>
              <div className={css.captionIcon}>
                <IconHeartFill size={15} />
              </div>
              {props.bookmarkCount}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default CardBizPost
