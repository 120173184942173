import { SEARCH_LOCAL_STORAGE_REFERRER_BRIDGE_KEY } from '../constants/common'

/**
 * @deprecated
 * 검색 Referrer Bridge
 * 검색 '디스커버 페이지'와 '검색 결과 페이지'가 네이티브 앱을 통해 분리되어 각각의 별도의 HTML로 운용되고 있는 상태 (딥링크로 호출)
 * 두 HTML 사이의 데이터를 넘겨받을 수 있게 임시 저장
 * @param state
 */
interface ReferrerBridgePersistedState {
  searchFunnelId: string
}

/**
 * @deprecated
 */
const getInitialReferrerBridgePersistedState =
  (): ReferrerBridgePersistedState => {
    return {
      searchFunnelId: '',
    }
  }
/**
 * @deprecated
 */
export const setReferrerBridgePersistedState = (
  state: Partial<ReferrerBridgePersistedState>
): ReferrerBridgePersistedState => {
  const prevState = getReferrerBridgePersistedState()
  const nextState = {
    ...prevState,
    ...state,
  }

  localStorage.setItem(
    SEARCH_LOCAL_STORAGE_REFERRER_BRIDGE_KEY,
    JSON.stringify(nextState)
  )

  return nextState
}
/**
 * @deprecated
 */
export const getReferrerBridgePersistedState =
  (): ReferrerBridgePersistedState => {
    const state = localStorage.getItem(SEARCH_LOCAL_STORAGE_REFERRER_BRIDGE_KEY)
    return {
      ...getInitialReferrerBridgePersistedState(),
      ...(state ? (JSON.parse(state) as ReferrerBridgePersistedState) : null),
    }
  }
