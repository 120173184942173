import type { MouseEvent } from 'react'
import IconContainerRecentSearch from './IconContainerRecentSearch'
import { IconClockLine, IconXmarkFill } from '@daangn/react-monochrome-icon'
import { vars } from '@seed-design/design-token'
import * as css from './RecentKeyword.css'

interface RecentKeywordProps {
  keyword: string
  eventTime?: string
  index: number
  onClick: (e: MouseEvent<HTMLLIElement>) => void
  onRemoveClick: (e: MouseEvent) => void
}

const RecentKeyword = (props: RecentKeywordProps) => {
  return (
    <li
      className={`search__recent-keyword ${css.root}`}
      data-recent-keyword-event-time={props.eventTime}
      onClick={props.onClick}
    >
      <div className={css.keywordContainer}>
        <div className={css.keywordWithIconContainer}>
          <IconContainerRecentSearch>
            <IconClockLine size={18} color={vars.$scale.color.gray600} />
          </IconContainerRecentSearch>
          <div className={css.keyword} role="button">
            {props.keyword}
          </div>
        </div>
        <button
          className={css.removeIconButton}
          aria-label="삭제"
          onClick={props.onRemoveClick}
        >
          <IconXmarkFill size={16} color={vars.$scale.color.gray600} />
        </button>
      </div>
    </li>
  )
}
export default RecentKeyword
