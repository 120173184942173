import type { ReactElement } from 'react'
import * as css from './SimpleError.css'

export const SimpleError = (props: { retry?: () => void }) => {
  const onRefreshClick = () => {
    props.retry?.()
  }

  return (
    <div className={css.container}>
      <div className={css.centered}>
        <div className={css.text}>
          알 수 없는 오류가 발생했습니다
          <br />
          인터넷 연결을 확인해주세요
        </div>
        {props.retry && (
          <button className={css.button} onClick={onRefreshClick}>
            다시 시도
          </button>
        )}
      </div>
    </div>
  )
}

export const SimpleErrorContainer = (props: { children: ReactElement }) => {
  return <div className={css.simpleErrorContainer}>{props.children}</div>
}
