import type { PropsWithChildren } from 'react'
import { forwardRef } from 'react'
import classNames from 'classnames'
import type {
  TouchableHighlightAs,
  TouchableHighlightElement,
} from '../_app/components/touchable-highlight'
import { TouchableHighlight } from '../_app/components/touchable-highlight'
import * as css from './CardCarItem.css'

interface CardCarItemProps {
  as: TouchableHighlightAs
  role?: string
  className?: string
  onClick?: () => void
}

const CardCarItem = forwardRef<
  TouchableHighlightElement<TouchableHighlightAs>,
  PropsWithChildren<CardCarItemProps>
>(({ as, role, className, children, onClick }, ref) => {
  return (
    <TouchableHighlight
      ref={ref}
      as={as}
      role={role}
      className={classNames(css.listItem, className)}
      onClick={onClick}
    >
      {children}
    </TouchableHighlight>
  )
})

export default CardCarItem
