import { SearchInputNavbar } from '@daangn/search-sdk'
import { useCallback } from 'react'
import type { ClientPlatformType } from '../../../bridge/utils/bridgeMaker'

interface SearchInputNavbarSkeletonProps {
  inputId?: string
  platform: ClientPlatformType
  inputValue: string
  onBackClick?: () => void
}
const SearchInputNavbarSkeleton = (props: SearchInputNavbarSkeletonProps) => {
  const emptyHandler = useCallback(() => {}, [])
  return (
    <SearchInputNavbar
      inputId={props.inputId}
      platform={props.platform}
      inputValue={props.inputValue}
      onBackClick={props.onBackClick ?? emptyHandler}
      onCancelClick={emptyHandler}
      onInputChange={emptyHandler}
      onSubmit={emptyHandler}
      hideRemoveIcon
    />
  )
}

export default SearchInputNavbarSkeleton
