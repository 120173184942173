import type { StateCreator } from 'zustand'

export const FLEA_MARKET_FILTER_SORT = {
  RELEVANT: {
    ID: 'RELEVANT',
    LABEL: '정확도순',
  },
  RECENT: {
    ID: 'RECENT',
    LABEL: '최신순',
  },
} as const

export type FleaMarketSortIdType =
  (typeof FLEA_MARKET_FILTER_SORT)[keyof typeof FLEA_MARKET_FILTER_SORT]['ID']

export interface FilterSortStateType {
  sortId: FleaMarketSortIdType
}

export type FleaMarketFilterSortSliceType = {
  fleaMarketFilterSort: {
    sortId: FleaMarketSortIdType

    changeSort: (sortId: FleaMarketSortIdType) => void
  }
}

export const fleaMarketFilterSortSlice: StateCreator<
  FleaMarketFilterSortSliceType
> = (set) => ({
  fleaMarketFilterSort: {
    sortId: FLEA_MARKET_FILTER_SORT.RELEVANT.ID,

    changeSort: (sortId: FleaMarketSortIdType) =>
      set((state) => ({
        fleaMarketFilterSort: {
          ...state.fleaMarketFilterSort,
          sortId,
        },
      })),
  },
})

export const sortLabelOf = (sortId: FleaMarketSortIdType) => {
  return (
    Object.values(FLEA_MARKET_FILTER_SORT).find((sort) => sort.ID === sortId)
      ?.LABEL ?? ''
  )
}

export const isDefaultSort = (sortId: FleaMarketSortIdType) => {
  return sortId === FLEA_MARKET_FILTER_SORT.RELEVANT.ID
}
