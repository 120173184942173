import type { PropsWithChildren } from 'react'
import { forwardRef } from 'react'
import classNames from 'classnames'
import type {
  TouchableHighlightAs,
  TouchableHighlightElement,
} from '../_app/components/touchable-highlight'
import { TouchableHighlight } from '../_app/components/touchable-highlight'
import * as css from './CardBizPostItem.css'

interface CardBizPostItemProps {
  as: TouchableHighlightAs
  role?: string
  className?: string
  onClick?: () => void
}

const CardBizPostItem = forwardRef<
  TouchableHighlightElement<TouchableHighlightAs>,
  PropsWithChildren<CardBizPostItemProps>
>(({ as, role, className, children, onClick }, ref) => {
  return (
    <TouchableHighlight
      ref={ref}
      as={as}
      role={role}
      className={classNames(css.listItem, className)}
      onClick={onClick}
    >
      {children}
    </TouchableHighlight>
  )
})

export default CardBizPostItem
