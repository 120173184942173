import { useMemo } from 'react'
import {
  ADAPTIVE_IMAGE_FORMAT,
  ADAPTIVE_IMAGE_RESIZABLE_OPTION,
  AdaptiveImage,
} from '../adaptive-image'
import * as css from './CardUser.css'

interface CardUserProps {
  nickname: string
  externalId: string
  regionName: string
  thumbnail: string
  fallbackThumbnail: string
}

const CardUser = (props: CardUserProps) => {
  const a11yLabel = useMemo(() => {
    return `
    이웃
    ${props.nickname} #${props.externalId}
    ${props.regionName}
  `
  }, [props.nickname, props.externalId, props.regionName])
  return (
    <div className={css.container} aria-label={a11yLabel}>
      <div className={css.imageContainer}>
        <AdaptiveImage
          className={css.image}
          src={props.thumbnail}
          fallbackSrc={props.fallbackThumbnail}
          options={[
            {
              size: '80x80',
              quality: 82,
              type: ADAPTIVE_IMAGE_RESIZABLE_OPTION.CROP,
              format: ADAPTIVE_IMAGE_FORMAT.WEBP,
            },
          ]}
        />
      </div>
      <div className={css.right}>
        <div className={css.rightTop}>
          <div className={css.name}>{props.nickname}</div>
          <div className={css.externalId}>#{props.externalId}</div>
        </div>
        <div className={css.regionName}>{props.regionName}</div>
      </div>
    </div>
  )
}

export default CardUser
