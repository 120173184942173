import { clientEnvironment, karrotBridge } from '../../bridge/utils/bridgeMaker'
import { karrotAnalyticsPlugin } from '@daangn/stackflow-plugin-karrot-analytics'

export const searchKarrotAnalyticsPlugin = () => {
  if (clientEnvironment.isWeb) {
    return () => ({
      key: 'do-not-applied-karrot-anlaytics-plugin',
    })
  }

  return karrotAnalyticsPlugin({
    bridge: karrotBridge,
    serviceName: 'search',
  })
}
