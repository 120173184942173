import 'src/packages/car/CardCar.css.ts.vanilla.css?source=#H4sIAAAAAAAAA51WTW/bMAy991cYBQZsBxlO2qSti5162c49DkNBW4ytVrYMiU6aDfvvkxQ7/kyRFEWQRsp7JB8fGYdPoLl9vaSqJBAl6peXBa2jehsFf6+CgAtTSdjHwUbi+6M9SJVUOg62oL8yZhA5MylIZP6cZRr27CGKvj1e/bsKW25RQIZP4wALH8DxxkFk/6Am5SLsBKc8Dtbh3Upj4U5yFFlOg6NEaY42j0X1HhglBf8wI5BVDmzl0rJQSN8yreqSs5lisICSRNqgK6hQsw4xJjDiD8ZWky3q0U2ljCChyjhYRV/cy90XoDNRMn0oZ9HU4tAbqXZxkAvOsXRnHVyjBBJb7KpmGriojVUtvGkEOUPs4Rfa86VvwlmSq+QVU2IbQf2Kz83oGPCX0en36+vfQ3uVqsQByPkRSzIt7mbeju6dcaFtZl4t27a6KB+PxlqcFrOwnWgKjwahcwTeKXd7XmCtdu74tTYkNnvWpB8HpoIUWYK0Q9fZXhgSJI+yrA7DYFGNp+Y8SftKWT9X+Z5JSFAumcaslqDZEegN6j/tmg5+gugA9VTSmojln6LqQQ9USGSt4gQRZXYx2wDtCY89sQknb4JYonxr2o8+gVRCUcXB0l1shRGJkIIsxv8vsf99C2dKC982a2+bD0h3T/hOrBtSlFJURphTo7uzI8ESjfAWB/7Nrh85M/1hO1ieH6TIrI0kbmjgkkTxfWuS9aUz0EQkVfnRXCwns5mCfq4wneyLu4tc75NngrCwOyC1Arrl0C8C+MxOur8oxqiW6ZrxQVruB889VDwKp/UPQbEEQyzNheRz+AESnEEkPhNQbSa1QR9/OueNUtSBkosEmd9qs62Y2UueFEs+lnb0K7yI3C9Xf5lbhqcciOwUtnmnh+UlpPz4wWA9eTCwZD/QKtky8c8ypVA5LVoe9DxnPKsciAabNwrv20GZacYJfU84bbRAJ2qO077EgA32Z9rhN/MGmjS/y/tEOW33w06JYw29s3HV3c0Z0t8eevgf3hCruIIKAAA=';
export var articleStatusContainer = 'CardCar_articleStatusContainer__1t60uva';
export var badge = 'CardCar_badge__1t60uv9';
export var badgeContainer = 'CardCar_badgeContainer__1t60uv8';
export var body = 'CardCar_body__1t60uv6';
export var caption = 'CardCar_caption__1t60uve';
export var captionIcon = 'CardCar_captionIcon__1t60uvf';
export var carSpecContainer = 'CardCar_carSpecContainer__1t60uv7';
export var container = 'CardCar_container__1t60uv0';
export var contents = 'CardCar_contents__1t60uv3';
export var footer = 'CardCar_footer__1t60uvb';
export var header = 'CardCar_header__1t60uv4';
export var iconChatting = 'CardCar_iconChatting__1t60uvc';
export var iconHeart = 'CardCar_iconHeart__1t60uvd';
export var image = 'CardCar_image__1t60uv2';
export var imageContainer = 'CardCar_imageContainer__1t60uv1';
export var title = 'CardCar_title__1t60uv5';