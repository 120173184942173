import 'src/packages/community/CardCommunity.css.ts.vanilla.css?source=#H4sIAAAAAAAAA+1Yy27bMBC85yuEAgWaAw3LzdNBT0EPvRYteiiKgBJpiTFFCiQVRy36711SliVZVGrJLtBDECRy+Jhd7ixnV57dY0XuZZYVgpnyIcNM3Eth4EHVwwPL9QWZz4NfZ0FAmM45LpfBitPnOxiwT0SYorFhUiyDWPIiE3dnv89mflAqTA87HIcdBBlWCRNIsSQ1y2A+u1pcKpq9bPUbM+mnDCe0Z35xmHklNy3bRubW8oBdXUQ9M+8nnnJr6eZ6wFYMn3xRvXD2VjCMNPtJl8ETVu8Q0pQS+JNhYViMTJnLROE8LVEkSYmyBVI0KThWaLfz/K7G2dAq4lOQqr0Oi4ObKJ2G1dpbYVFjqEI6xzETyXi4znaHCARI1QWKMafIjSPAKdH1fO6W5pgQZ3U+27ITBJFUBBAVJqzQduZ9M4XjdaJkIQjyGKm9rezkOAeUuMpdfe7hPcIEkhkbmkhV1qRfjiSd44jyyxNw7gOaSPke1JGM76P1Ce9dScxZIhAzNAMCY4g/VXb4sdCGrcqalPZUKxFCp0Vd3vdTIlw0U8823m7vdhkM2ZlNCg44P4FFITdwmr/n0H6ihttEHZHTXW3FhZGe3DPM8L6QXk1SnPBkihOeUHHC0ypOeKTi3Dp2ejwQqmPFcls5emxcOza6RWSXd69l4Z+VhR5JzNt13FSXBSQHiIGf6qbBvWfEpMvgYlZTVUekGRlfR5od54fXqCo9YvlUaVxrJpeaVd3K5fyt/XUauBtUlGPDnqgdtbtXXG6WQcoIocIruAOtVk9w3ToqSEunOF0Ny9RQ5H3L6tlbx8sBLMjoEfxFK2baQRqKrN+3ndHvWsUf3rz50e0RhRR0+FiFMPV+7PY1DOBIQxtpHAPV1a8uhY3VfGwK2bOBO0BQttf1dDKnjlDYqMyhlXVX+YyRWVV6vMn52teM6Ws8xBrc0Lr9x/UZPtmyLNtswvyTWMneLYqOfmsaSIeeI4Wm6jPFDlD3/CCj/DhaUtrO9HyhU2Kye4sdeol92eSSY21QnDJO2gW/RvXJR+zBWbVlL2x1qM21bsYOqIUX/lrYPsuXtMiir3lHyJKRLWSMXfNzih7SDzXxrvfAjrztfbxJHcpVt8nfFoQdsy/S9ZnmvOyQlb6S9T+RZb2+5zjL7VddHzkHkpiuuWJdaYJQRWtm6pfNJ6ZZxDigLKvP3LUOrVVIKuYEE5oBOC7mrrWgzwY1HR7d2hzq+zZQ5lGkKF7Dy659IMz5wQfZjT6E9aEe3aFqLx1rsV0BajUBdlHDrgdhFwD7B5eaZCIuFQAA';
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var additionalInfoContainer = 'CardCommunity_additionalInfoContainer__ips4d0b';
export var badgeCategory = 'CardCommunity_badgeCategory__ips4d05';
export var commentContainer = 'CardCommunity_commentContainer__ips4d04';
export var descriptionContainer = 'CardCommunity_descriptionContainer__ips4d07';
export var iconContainer = 'CardCommunity_iconContainer__ips4d0f';
export var image = 'CardCommunity_image__ips4d09';
export var imageContainer = 'CardCommunity_imageContainer__ips4d08';
export var imageCount = 'CardCommunity_imageCount__ips4d0a';
export var lineClampWithEllipsis = _7a468({defaultClassName:'CardCommunity_lineClampWithEllipsis__ips4d0i',variantClassNames:{lineClamp:{'1':'CardCommunity_lineClampWithEllipsis_lineClamp_1__ips4d0j','2':'CardCommunity_lineClampWithEllipsis_lineClamp_2__ips4d0k'}},defaultVariants:{lineClamp:2},compoundVariants:[]});
export var mainContainer = 'CardCommunity_mainContainer__ips4d00';
export var mainContentContainer = 'CardCommunity_mainContentContainer__ips4d01';
export var mainContentWithImageContainer = 'CardCommunity_mainContentWithImageContainer__ips4d02';
export var subContainer = 'CardCommunity_subContainer__ips4d03';
export var tagGroupContainer = 'CardCommunity_tagGroupContainer__ips4d0c';
export var titleContainer = 'CardCommunity_titleContainer__ips4d06';
export var userReactionContainer = 'CardCommunity_userReactionContainer__ips4d0e';
export var userReactionReplyCount = 'CardCommunity_userReactionReplyCount__ips4d0h';
export var userReactionThumbUpCount = 'CardCommunity_userReactionThumbUpCount__ips4d0g';
export var userReactionsContainer = 'CardCommunity_userReactionsContainer__ips4d0d';