export const CAR_BADGE_STYLE_TYPE = {
  DEFAULT: 'default',
  TRADED: 'traded',
  RESERVE: 'reserve',
} as const

export type CarBadgeStyleType =
  (typeof CAR_BADGE_STYLE_TYPE)[keyof typeof CAR_BADGE_STYLE_TYPE]

export const CAR_BADGE_STYLE_TYPE_TO_PROPS = {
  [CAR_BADGE_STYLE_TYPE.DEFAULT]: {
    variant: 'basic',
    type: 'normal',
  },
  [CAR_BADGE_STYLE_TYPE.TRADED]: {
    variant: 'basic',
    type: 'bold',
  },
  [CAR_BADGE_STYLE_TYPE.RESERVE]: {
    variant: 'success',
    type: 'bold',
  },
} as const
