import { HotKeywordsSkeleton, RecentSearchSkeleton } from '@daangn/search-sdk'
import * as css from './SearchDiscover.css'

const SearchDiscoverSkeleton = () => {
  return (
    <div className={css.container}>
      <HotKeywordsSkeleton />
      <RecentSearchSkeleton />
    </div>
  )
}

export default SearchDiscoverSkeleton
