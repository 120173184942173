import { IntegrateSearchSuccessResponseCollectionRanksEnum } from '../../__codegen__/__openapi__/search-front-server'
import { COLLECTION_TYPE } from '../constants'

export const convertToCollectionRanksConstants = (
  tabs: IntegrateSearchSuccessResponseCollectionRanksEnum[]
) => {
  return tabs
    .map((tab) => {
      switch (tab) {
        case IntegrateSearchSuccessResponseCollectionRanksEnum.ADVERTISEMENT:
          return COLLECTION_TYPE.AD
        case IntegrateSearchSuccessResponseCollectionRanksEnum.SHORTCUT:
          return COLLECTION_TYPE.SHORTCUT
        case IntegrateSearchSuccessResponseCollectionRanksEnum.FLEAMARKET:
          return COLLECTION_TYPE.FLEA_MARKET
        case IntegrateSearchSuccessResponseCollectionRanksEnum.COMMUNITY:
          return COLLECTION_TYPE.COMMUNITY
        case IntegrateSearchSuccessResponseCollectionRanksEnum.COMMERCE:
          return COLLECTION_TYPE.COMMERCE
        case IntegrateSearchSuccessResponseCollectionRanksEnum.BUSINESS_POSTS:
          return COLLECTION_TYPE.BUSINESS
        case IntegrateSearchSuccessResponseCollectionRanksEnum.PLACE:
          return COLLECTION_TYPE.PLACE
        case IntegrateSearchSuccessResponseCollectionRanksEnum.JOB:
          return COLLECTION_TYPE.JOB
        case IntegrateSearchSuccessResponseCollectionRanksEnum.CAR:
          return COLLECTION_TYPE.CAR
        case IntegrateSearchSuccessResponseCollectionRanksEnum.REALTY:
          return COLLECTION_TYPE.REALTY
        case IntegrateSearchSuccessResponseCollectionRanksEnum.GROUP:
          return COLLECTION_TYPE.KARROT_GROUP
        case IntegrateSearchSuccessResponseCollectionRanksEnum.CATALOG_PRODUCT_AD:
          return COLLECTION_TYPE.CATALOG_PRODUCT_AD
        case IntegrateSearchSuccessResponseCollectionRanksEnum.UNSPECIFIED:
        default:
          return COLLECTION_TYPE.UNKNOWN
      }
    })
    .filter(Boolean)
}
