import type { StateCreatorWithDevtools } from '../../__development/zustand'

export type PlaceMapSliceType = {
  placeMap: {
    isMapVisible: boolean

    changeMapVisibility: (isVisible: boolean) => void
  }
}

export const placeMapSlice: StateCreatorWithDevtools<PlaceMapSliceType> = (
  set
) => ({
  placeMap: {
    isMapVisible: false,

    changeMapVisibility: (isVisible: boolean) =>
      set(
        (state) => ({
          placeMap: {
            ...state.placeMap,
            isMapVisible: isVisible,
          },
        }),
        false,
        'placeMap/changeMapVisibility'
      ),
  },
})
