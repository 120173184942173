import { AxiosError } from 'axios'
type ClientTimeoutErrorType = {
  code: 'ECONNABORTED'
}

export const isClientTimeoutError = (
  error: unknown
): error is AxiosError<ClientTimeoutErrorType> => {
  return error instanceof AxiosError && error.code === 'ECONNABORTED'
}
