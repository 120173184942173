import snakecaseKeys from 'snakecase-keys'
import { omitBy } from 'lodash-es'
import { LOG_STATUS } from './constants'
import type { LogType } from './types'

/**
 * element가 viewport 안에 노출되었는지 확인하는 함수
 * @param element target element to assert that element is visible in viewport
 * @param strict If true, all 4 corners in viewport. If false, at least 1 corner is in viewport
 * @param threshold ratio of element to be visible in viewport
 */
export const isInViewport = (
  element: HTMLElement,
  strict: boolean = false,
  threshold: number = 1
): boolean => {
  const elRect = element.getBoundingClientRect()
  const viewportOffset = getViewportOffset(element, threshold)
  const viewportRect = {
    top: 0,
    left: 0,
    bottom: window.innerHeight || document.documentElement.clientHeight,
    right: window.innerWidth || document.documentElement.clientWidth,
  }
  if (strict) {
    return (
      elRect.top >= viewportRect.top - viewportOffset.top &&
      elRect.left >= viewportRect.left - viewportOffset.left &&
      elRect.bottom <= viewportRect.bottom + viewportOffset.bottom &&
      elRect.right <= viewportRect.right + viewportOffset.right
    )
  } else {
    return (
      elRect.top <= viewportRect.bottom + viewportOffset.bottom &&
      elRect.left <= viewportRect.right + viewportOffset.right &&
      elRect.bottom >= viewportRect.top - viewportOffset.top &&
      elRect.right >= viewportRect.left - viewportOffset.left
    )
  }
}

/**
 * 주어진 threshold 값만큼 viewport offset 값을 반환하는 함수
 */
export const getViewportOffset = (
  element: HTMLElement,
  threshold: number = 1
) => {
  const inversedThreshold = 1 - threshold
  const elRect = element.getBoundingClientRect()
  const offsetRect = {
    top: elRect.height * inversedThreshold,
    left: elRect.width * inversedThreshold,
    bottom: elRect.height * inversedThreshold,
    right: elRect.width * inversedThreshold,
  }
  return offsetRect
}
/**
 * 주어진 element가 실제 viewport에 영역을 차지하고 있는지 체크하는 함수
 */
export const isElementVisible = (element: HTMLElement) => {
  return element.offsetHeight !== 0 || element.offsetWidth !== 0
}

export const visualizeLogging = () => {
  const initState = {
    visualiseObserver: null,
    doVisualise: null,
    disconnectVisualise: null,
  }
  if (!Object.prototype.hasOwnProperty.call(window, 'MutationObserver')) {
    console.log('Mutation Observer API 미지원 브라우저')
    return initState
  }
  const createOverlay = (targetEl: HTMLElement) => {
    targetEl.dataset.devOnlyStylePosition = targetEl.style.position
    const overlayEl = document.createElement('div')

    overlayEl.style.position = 'absolute'
    overlayEl.style.top = '0'
    overlayEl.style.left = '0'
    overlayEl.style.width = '100%'
    overlayEl.style.height = '100%'
    overlayEl.style.zIndex = '9999'
    overlayEl.style.fontSize = '8px'
    overlayEl.style.display = 'flex'
    overlayEl.style.flexDirection = 'row'
    overlayEl.style.overflow = 'hidden'
    overlayEl.dataset.devOnlyOverlay = '__DEV_ONLY__'

    return {
      getOverlayEl: () => {
        return overlayEl
      },
      updateOverlayText: (text: string) => {
        const textNodeLeft = document.createElement('div')
        const textNodeRight = document.createElement('div')
        textNodeLeft.style.width = '50%'
        textNodeRight.style.width = '50%'

        let json: { [key: string]: any }
        try {
          json = JSON.parse(text)
        } catch (e) {
          console.error('Invalid JSON string', e)
          return
        }

        const keys = Object.keys(json)
        const half = Math.ceil(keys.length / 2)

        type DataObject = { [key: string]: any }

        const leftData: DataObject = keys
          .slice(0, half)
          .reduce((obj: DataObject, key: string) => {
            obj[key] = json[key]
            return obj
          }, {})

        const rightData: DataObject = keys
          .slice(half)
          .reduce((obj: DataObject, key: string) => {
            obj[key] = json[key]
            return obj
          }, {})

        function createDivFromObject(object: DataObject): HTMLDivElement {
          const containerDiv = document.createElement('div')
          Object.entries(object).forEach(([key, value]) => {
            const itemDiv = document.createElement('div')

            const keyElement = document.createElement('strong')
            if (value !== null && value !== undefined) {
              keyElement.textContent = `${key}: `

              itemDiv.appendChild(keyElement)
              if (Array.isArray(value)) {
                itemDiv.appendChild(document.createTextNode('...'))
              } else if (typeof value === 'object' && value !== null) {
                itemDiv.appendChild(createDivFromObject(value))
              } else {
                itemDiv.appendChild(document.createTextNode(String(value)))
              }

              containerDiv.appendChild(itemDiv)
            }
          })
          return containerDiv
        }

        textNodeLeft.appendChild(createDivFromObject(leftData))
        textNodeRight.appendChild(createDivFromObject(rightData))

        overlayEl.appendChild(textNodeLeft)
        overlayEl.appendChild(textNodeRight)
      },
      updateColor: (color: 'red' | 'blue' | 'green') => {
        overlayEl.style.backgroundColor = color
        overlayEl.style.opacity = '0.8'
        targetEl.style.position = 'relative'
      },
    }
  }
  const removeAllOverlay = (target: HTMLElement) => {
    target
      .querySelectorAll('[data-dev-only-overlay="__DEV_ONLY__"]')
      .forEach((el) => {
        el.remove()
      })
  }

  const doColor = (target: HTMLElement) => {
    removeAllOverlay(target)
    const overlay = createOverlay(target)
    const log = target.dataset.devOnlyShowLog
    overlay.updateOverlayText(log ?? 'undefined')

    switch (target.dataset.logStatus) {
      case LOG_STATUS.OBSERVED: {
        overlay.updateColor('red')
        break
      }
      case LOG_STATUS.COOL: {
        overlay.updateColor('blue')
        break
      }
      case LOG_STATUS.OBSERVING: {
        overlay.updateColor('green')
        break
      }
      case LOG_STATUS.INIT:
      default:
      //do nothing
    }
    target.appendChild(overlay.getOverlayEl())
  }

  const mutationObserver = new MutationObserver((mutationRecords) => {
    mutationRecords.forEach((mutationRecord) => {
      const observerTarget = mutationRecord.target as HTMLElement
      if (mutationRecord.attributeName !== 'data-log-status') return
      if (!observerTarget.dataset.logStatus) return
      doColor(observerTarget)
    })
  })

  const doVisualise = () => {
    const els: NodeListOf<HTMLElement> =
      document.querySelectorAll('[data-log-status]')
    els.forEach((el) => {
      doColor(el)
    })
    mutationObserver.observe(document.body, {
      attributes: true,
      childList: false,
      characterData: false,
      subtree: true,
    })
  }

  const disconnectVisualise = () => {
    mutationObserver.disconnect()
    const els: NodeListOf<HTMLElement> =
      document.querySelectorAll('[data-log-status]')

    els.forEach((el) => {
      const targetElPosition = el.dataset.devOnlyStylePosition
      delete el.dataset.devOnlyStylePosition
      if (targetElPosition) {
        el.style.position = targetElPosition
      }
      removeAllOverlay(el)
    })
  }
  return {
    ...initState,
    visualiseObserver: mutationObserver,
    doVisualise,
    disconnectVisualise,
  }
}

export const dummyLogger = ({ name, params = {} }: LogType) => {
  console.log({
    target: 'DUMMY_LOGGER',
    name: `client_${name}`,
    params: snakecaseKeys({
      ...compactMap(params),
    }),
  })
}

/**
 * Creates an object with all null values removed.
 * @param obj The object to compact
 */
export function compactMap<T extends {}>(
  obj: T
): {
  [key in keyof T]: NonNullable<T[key]>
} {
  return omitBy(obj, (value) => value === null || value === undefined) as any
}
