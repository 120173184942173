export const COLLECTION_TYPE = {
  AD: 'AD',
  FLEA_MARKET: 'FLEA_MARKET',
  COMMUNITY: 'COMMUNITY',
  BUSINESS: 'BUSINESS',
  PLACE: 'PLACE',
  SHORTCUT: 'SHORTCUT',
  JOB: 'JOB',
  COMMERCE: 'COMMERCE',
  CAR: 'CAR',
  REALTY: 'REALTY',
  KARROT_GROUP: 'KARROT_GROUP',
  CATALOG_PRODUCT_AD: 'CATALOG_PRODUCT_AD',
  UNKNOWN: 'UNKNOWN',
} as const

export type CollectionType =
  (typeof COLLECTION_TYPE)[keyof typeof COLLECTION_TYPE]

export const DEFAULT_COLLECTION_RANK = [
  COLLECTION_TYPE.SHORTCUT,
  COLLECTION_TYPE.AD,
  COLLECTION_TYPE.FLEA_MARKET,
  COLLECTION_TYPE.CATALOG_PRODUCT_AD,
  COLLECTION_TYPE.COMMUNITY,
  COLLECTION_TYPE.PLACE,
  COLLECTION_TYPE.BUSINESS,
  COLLECTION_TYPE.JOB,
  COLLECTION_TYPE.CAR,
  COLLECTION_TYPE.KARROT_GROUP,
  COLLECTION_TYPE.COMMERCE,
  COLLECTION_TYPE.REALTY,
]
