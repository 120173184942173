import type { Plugin } from 'plantae'

/**
 * search-server v4 rpc부터 디버깅을 위해 x-search-user-id를 받고 있어요. authToken이 없을 때 user-id를 통해서 유저정보를 확인하는 데 사용돼요.
 */
export default function plantaeSearchUserIdPlugin({
  userId,
}: {
  userId: number
}): Plugin {
  return {
    name: 'plugin-search-user-id',
    hooks: {
      beforeRequest: async (req) => {
        req.headers.set('x-search-user-id', userId.toString())
        return req
      },
    },
  }
}
