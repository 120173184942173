import type { ReactNode } from 'react'
import { useEffect, useMemo } from 'react'
import {
  ImpressionRoot,
  ImpressionObserver,
  disableImpression,
  enableImpression,
} from '@daangn/webview-impression'
import { useLogger } from '../../../referrer/hooks/useLogger'
import { STAGE } from '../../constants/common'
import type { ImpressionLogParamsType } from '../../../referrer/types'
import { useImpressionContext } from '../../context/AppInitializeProvider'
import { appBuildType } from '../../utils/userAgent'

interface ImpressionLoggerRootProps {
  children: ReactNode
}
export const ImpressionLoggerRoot = (props: ImpressionLoggerRootProps) => {
  const { daangnSDKLogEvent } = useLogger()
  const impressionInfo = useImpressionContext()

  const hasImpressionInfo = useMemo(() => {
    return Object.values(impressionInfo).every((option) => option ?? false)
  }, [impressionInfo])

  const isDevMode = useMemo(() => {
    const isLocal = 'development' === STAGE
    const isDebugApp = appBuildType(window.navigator.userAgent).isDebugApp
    return isLocal || isDebugApp
  }, [])

  const impressionOptions = useMemo(() => {
    return {
      ...impressionInfo,
    }
  }, [impressionInfo])

  return (
    <ImpressionRoot
      logger={daangnSDKLogEvent}
      impressionOptions={impressionOptions}
      initialized={hasImpressionInfo}
      DEV_ONLY_show_debug_overlay={isDevMode}
      DEV_ONLY_showLogData={isDevMode}
    >
      {props.children}
    </ImpressionRoot>
  )
}

interface ImpressionLogObserverProps {
  observeTargetRef: { current: HTMLElement | null }
  log?: ImpressionLogParamsType
  children: ReactNode
  disable?: boolean
  index?: number
}
export const ImpressionLogObserver = (props: ImpressionLogObserverProps) => {
  useEffect(() => {
    const targetEl = props.observeTargetRef.current
    if (!targetEl) return
    if (props.disable) {
      disableImpression(targetEl)
    } else {
      enableImpression(targetEl)
    }
  })
  return props.log ? (
    <ImpressionObserver
      observeTargetRef={props.observeTargetRef}
      log={props.log}
    >
      {props.children}
    </ImpressionObserver>
  ) : (
    <>{props.children}</>
  )
}
