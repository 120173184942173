import type { Plugin } from 'plantae'
import { VERSION } from '../../_app/constants/common'

/**
 * BFF 요청 보낼 때 검색 웹뷰 클라이언트 버전을 헤더에 추가하는 플러그인
 */
export default function plantaeSearchWebVersionPlugin(): Plugin {
  return {
    name: 'plugin-search-web-version',
    hooks: {
      beforeRequest: async (req) => {
        req.headers.set('x-search-web-version', VERSION)

        return req
      },
    },
  }
}
