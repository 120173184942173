import {
  executeByEnvironment,
  karrotBridge,
} from '../../../bridge/utils/bridgeMaker'
import { SafeAreaInsetTop } from '../safe-area-inset/SafeAreaInset'
import * as css from './MaintenanceErrorPanel.css'

export const MaintenanceErrorPanel = (props: { message: string }) => {
  return (
    <div className={css.container}>
      <SafeAreaInsetTop />
      <div className={css.errorContainer}>
        <div className={css.centered}>
          <div className={css.text}>{props.message}</div>
          <button
            className={css.button}
            onClick={() => {
              executeByEnvironment({
                onApp: () => karrotBridge.closeRouter({}),
                onWeb: () => window.history.back(),
              })
            }}
          >
            뒤로 가기
          </button>
        </div>
      </div>
    </div>
  )
}
