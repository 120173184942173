import type { ApiV1InitializeGet200ResponseExperimentUserExperimentSegmentsInnerTypeEnum } from '../../__codegen__/__openapi__/search-front-server'

/**
 * 실험 세그먼트 유형
 * normal: 일반
 * lazy: 커스텀 트리거로만 할당되는 세그먼트
 */
export type ExperimentSegmentStatusType = Lowercase<
  keyof typeof ApiV1InitializeGet200ResponseExperimentUserExperimentSegmentsInnerTypeEnum
>

export const ExperimentSegmentStatusTypeMapper: Record<
  ApiV1InitializeGet200ResponseExperimentUserExperimentSegmentsInnerTypeEnum,
  ExperimentSegmentStatusType
> = {
  EXPERIMENT_TYPE_LAZY: 'lazy',
  EXPERIMENT_TYPE_NORMAL: 'normal',
  EXPERIMENT_TYPE_UNSPECIFIED: 'unspecified',
}

/**
 * 실험 커스텀 트리거 활성화 여부를 판단하기위한 응답헤더 키 값
 */
export const SEARCH_EXPERIMENT_CUSTOM_TRIGGER_FLAG_HEADER_KEY =
  'x-search-experiment-custom-triggered'

/**
 * 실험 세그먼트 응답헤더 키값
 */
export const SEARCH_EXPERIMENT_SEGMENT_HEADER_KEY =
  'x-search-experiment-segments'

/**
 * Response Header에서 실험 정보를 주입해주기 위한 응답 body 키값
 */
export const SEARCH_EXPERIMENT_RESPONSE_KEY = 'experiment'

/**
 * 실험 세그먼트 명
 */
export const EXPERIMENT_SEGMENT_NAME = {
  CONTROL: 'control', // 기존 화면
  TREATMENT_1: 'treatment1',
  TREATMENT_2: 'treatment2',
  TREATMENT_3: 'treatment3',
  TREATMENT_4: 'treatment4',
  TREATMENT_5: 'treatment5',
  TREATMENT_6: 'treatment6',
}
