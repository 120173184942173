import to from 'await-to-js'
import axios from 'axios'
import axiosRetry from 'axios-retry'

import {
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_FRONT_SERVER_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeRequestIdPlugin,
  plantaeUserAgentPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeCommonHeadersPlugin,
  plantaeSearchOriginPlugin,
  plantaeKarrotUserIdPlugin,
  plantaeSearchWebVersionPlugin,
} from '../../plantae/plugins'
import { FleamarketApi } from '../../__codegen__/__openapi__/search-front-server'

let serviceCache: ServiceKarrotMarketType | null = null
export type ServiceKarrotMarketType = ReturnType<typeof ServiceKarrotMarket>

export function getServiceKarrotMarket({
  app,
  user,
}: {
  app: AppType
  user: UserType
}) {
  if (serviceCache) {
    return serviceCache
  }

  return (serviceCache = ServiceKarrotMarket({
    baseUrl: SEARCH_FRONT_SERVER_ENDPOINT,
    app,
    user,
  }))
}

const ServiceKarrotMarket = ({
  baseUrl,
  app,
  user,
}: {
  baseUrl: string
  app: AppType
  user: UserType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }
  const axiosInstance = axios.create({
    headers,
    timeout: NETWORK_TIMEOUT,
  })

  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: [
      plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
      plantaeRequestIdPlugin(),
      plantaeKarrotSessionIdPlugin({ app }),
      plantaeUserAgentPlugin({ userAgent: app.userAgent }),
      plantaeKarrotUserIdPlugin({ app, userId: user.id }),
      plantaeCommonHeadersPlugin(),
      plantaeSearchOriginPlugin(),
      plantaeSearchWebVersionPlugin(),
    ],
  })

  axiosRetry(axiosInstance, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
    shouldResetTimeout: true,
  })

  const client = new FleamarketApi(undefined, baseUrl, axiosInstance)

  return {
    /**
     * 중고거래 필터, 지역 범위
     * @param regionId
     */
    async getRegionRanges({ regionId }: { regionId: number }) {
      const [error, resp] = await to(
        client.apiV1FleamarketRegionRegionRangesGet({
          regionId: regionId.toString(),
          rangeType: 'view',
        })
      )

      if (error || !resp?.data) {
        captureException(error)
        return null
      }

      if ('status' in resp.data) {
        return null
      }

      return resp.data.regionRanges
    },
  }
}
