import * as css from './Highlight.css'

type InnerHTMLType = {
  __html: string
}
interface HighlightProps {
  className?: string
  dangerouslySetInnerHTML: InnerHTMLType
}

const Highlight = (props: HighlightProps) => {
  return (
    <span
      className={[css.highlight, props.className].join(' ')}
      dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
    ></span>
  )
}

export default Highlight
