import type { ApiV1InitializeGet200ResponseExperimentUser } from '../../__codegen__/__openapi__/search-front-server'
import { ExperimentSegmentStatusTypeMapper } from '../constants/experiment'
import type { ExperimentStateType } from '../stores/ExperimentSlice'

export const experimentStateMapper = (
  experiment: ApiV1InitializeGet200ResponseExperimentUser
): ExperimentStateType => {
  const experimentSegments = experiment.experimentSegments.map((segment) => {
    return {
      segmentKey: segment.experimentKey,
      segmentName: segment.segmentName,
      segmentType: ExperimentSegmentStatusTypeMapper[segment.type],
    }
  })

  return {
    experimentSegments: experimentSegments,
    headerSegmentKey: experiment.headerSegmentKey,
    headerSegmentValue: experiment.headerSegmentValue,
  }
}
