import { useMemo } from 'react'

export const useDistanceWithUnit = (distance: number) => {
  const distanceWithUnit = useMemo(() => {
    let text

    if (distance < 10000) {
      return `${distance.toString()}km`
    }
    text = distance / 10000
    text = distance % 10000 === 0 ? text.toString() : text.toFixed(1)

    return `${text}만km`
  }, [distance])

  return distanceWithUnit
}
