import type { TagGroupSizeType } from './constants'
import { TAG_GROUP_SIZE } from './constants'
import * as css from './TagDivider.css'
import classNames from 'classnames'

type TagDividerProps = {
  className?: string
  size?: TagGroupSizeType
}

export const TagDivider = ({
  className,
  size = TAG_GROUP_SIZE.MEDIUM,
}: TagDividerProps) => {
  return <span className={classNames(css.dotDivider({ size }), className)} />
}

TagDivider.displayName = 'TagDivider'
