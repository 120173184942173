import type { Plugin } from 'plantae'
import type { AppType } from '../../_app/context/FoundationProvider'
import {
  satisfyAndroidVersion,
  satisfyIosVersion,
} from '../../_app/utils/version'

/**
 * https://www.notion.so/daangn/HTTP-9d4e958852dd45ba9bb85ae60721bff5?pvs=4
 *
 * common-headers-plugin에서 android(>=23.46.1), ios(>=23.46.0) 버전부터
 * x-karrot-user-id 헤더를 심어줘서 하위 호환을 위해 karrotUserIdPlugin을 만들었어요.
 *  */
export default function plantaeKarrotUserIdPlugin({
  app,
  userId,
}: {
  app: AppType
  userId: number
}): Plugin {
  return {
    name: 'plugin-karrot-user-id',
    hooks: {
      beforeRequest: async (req) => {
        if (
          satisfyAndroidVersion(app, '>=23.46.1') ||
          satisfyIosVersion(app, '>=23.46.0')
        ) {
          return req
        }

        req.headers.set('x-karrot-user-id', userId.toString())
        return req
      },
    },
  }
}
