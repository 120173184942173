export const ADAPTIVE_IMAGE_RESIZABLE_OPTION = {
  CROP: 'crop',
  COVER: 'cover',
  INSIDE: 'inside',
} as const

export type AdaptiveImageResizableOptionType =
  (typeof ADAPTIVE_IMAGE_RESIZABLE_OPTION)[keyof typeof ADAPTIVE_IMAGE_RESIZABLE_OPTION]

export const ADAPTIVE_IMAGE_FORMAT = {
  JPG: 'jpg',
  JPEG: 'jpeg',
  PNG: 'png',
  GIF: 'gif',
  WEBP: 'webp',
  SVG: 'svg',
  TIFF: 'tiff',
} as const

export type AdaptiveImageFormatType =
  (typeof ADAPTIVE_IMAGE_FORMAT)[keyof typeof ADAPTIVE_IMAGE_FORMAT]
