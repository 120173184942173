import { useEffect } from 'react'
import { visualizeLogging } from '../../../../core/common/utils'
const useVisualiseLogging = ({
  isVisualiseEnabled,
}: {
  isVisualiseEnabled: boolean
}) => {
  useEffect(() => {
    const { visualiseObserver, doVisualise, disconnectVisualise } =
      visualizeLogging()
    if (!visualiseObserver) return

    if (isVisualiseEnabled) {
      doVisualise && doVisualise()
    } else {
      disconnectVisualise && disconnectVisualise()
    }
    return () => {
      visualiseObserver && disconnectVisualise && disconnectVisualise()
    }
  }, [isVisualiseEnabled])
}

export default useVisualiseLogging
