import type { MouseEvent, ReactNode } from 'react'
import { forwardRef, memo, useMemo } from 'react'
import classNames from 'classnames'
import { TAG_SIZE } from './constants'
import * as css from './Tag.css'
import type { RecipeVariants } from '@vanilla-extract/recipes'

export type TagProps = {
  iconClassName?: string
  className?: string
  children: ReactNode
  [TAG_SIZE.SMALL]?: boolean
  [TAG_SIZE.MEDIUM]?: boolean
  [TAG_SIZE.LARGE]?: boolean
  bold?: boolean
  icon?: ReactNode
  leadingIcon?: ReactNode
  color?: NonNullable<RecipeVariants<typeof css.tag>>['color']
  onClick?: (e: MouseEvent<HTMLSpanElement>) => void
  role?: string
  tabIndex?: number
}

const Tag = forwardRef<HTMLDivElement, TagProps>(
  (
    {
      small = false,
      medium = true,
      large = false,
      bold = false,
      color,
      ...props
    },
    ref
  ) => {
    const size = useMemo(() => {
      if (small) {
        return TAG_SIZE.SMALL
      } else if (large) {
        return TAG_SIZE.LARGE
      } else {
        return TAG_SIZE.MEDIUM
      }
    }, [small, large])

    return (
      <>
        {props.icon ? (
          <span className={classNames(css.icon, props.iconClassName)}>
            {props.icon}
          </span>
        ) : null}
        <span
          ref={ref}
          role={props.role}
          tabIndex={props.tabIndex}
          className={classNames(
            css.tag({
              size,
              bold,
              color,
            }),
            props.className
          )}
          onClick={props.onClick}
        >
          {props.children}
        </span>
        {props.leadingIcon ? (
          <span className={css.leadingIcon}>{props.leadingIcon}</span>
        ) : null}
      </>
    )
  }
)

Tag.displayName = 'Tag'

export default memo(Tag)
