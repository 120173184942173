import type { ForwardedRef, ReactNode, PropsWithoutRef } from 'react'
import { forwardRef } from 'react'
import classNames from 'classnames'
import * as css from './TouchableHighlight.css'

export type TouchableHighlightAs = 'li' | 'div'

export type TouchableHighlightElement<T extends TouchableHighlightAs> =
  T extends 'li' ? HTMLLIElement : HTMLDivElement

export type TouchableHighlightProps<T extends TouchableHighlightAs> =
  T extends TouchableHighlightAs
    ? PropsWithoutRef<
        JSX.IntrinsicElements[T] & {
          as: T
          className?: string
          children?: ReactNode
        }
      >
    : never

const TouchableHighlight = forwardRef(
  <T extends TouchableHighlightAs>(
    { as, className, children, ...props }: TouchableHighlightProps<T>,
    ref: ForwardedRef<TouchableHighlightElement<T>>
  ) => {
    const Component = as as React.ElementType

    return (
      <Component
        ref={ref}
        className={classNames(css.container, className)}
        tabIndex={0}
        {...props}
      >
        {children}
      </Component>
    )
  }
)

export default TouchableHighlight
