import { AxiosError } from 'axios'

export interface MaintenanceErrorType {
  status?: {
    code: string
    message: string
    type: string
  }
}

export const MAINTENANCE_ERROR_CODE = 599

export const isMaintenanceError = (
  error: unknown
): error is AxiosError<MaintenanceErrorType> => {
  return error instanceof AxiosError && error.status === MAINTENANCE_ERROR_CODE
}
