export const CATALOG_PRODUCT_AD_VIEW_TYPE = {
  LIST_VIEW: 'LIST_VIEW',
  CAROUSEL: 'CAROUSEL',
} as const

export type CatalogProductAdViewType =
  (typeof CATALOG_PRODUCT_AD_VIEW_TYPE)[keyof typeof CATALOG_PRODUCT_AD_VIEW_TYPE]

export const CATALOG_PRODUCT_AD_COMPONENT_TYPE = {
  CATALOG_ADVERTISEMENT: 'CATALOG_ADVERTISEMENT',
  CAROUSEL_ADVERTISEMENT: 'CAROUSEL_ADVERTISEMENT',
  UNKNOWN: 'UNKNOWN',
} as const

export const SEARCH_AD_MATERIAL_SOURCE_TYPE = {
  MATERIAL_SOURCE_TYPE_BUSINESS_PROFILE:
    'MATERIAL_SOURCE_TYPE_BUSINESS_PROFILE',
  MATERIAL_SOURCE_TYPE_BUSINESS_POST: 'MATERIAL_SOURCE_TYPE_BUSINESS_POST',
  MATERIAL_SOURCE_TYPE_UNSPECIFIED: 'MATERIAL_SOURCE_TYPE_UNSPECIFIED',
} as const

export type SearchAdMaterialSourceType =
  (typeof SEARCH_AD_MATERIAL_SOURCE_TYPE)[keyof typeof SEARCH_AD_MATERIAL_SOURCE_TYPE]
