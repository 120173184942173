import type { MouseEvent, ReactNode } from 'react'
import IconContainerRecentSearch from './IconContainerRecentSearch'
import * as css from './RecentKeyword.css'
import { IconClockLine, IconXmarkFill } from '@daangn/react-monochrome-icon'
import { vars } from '@seed-design/design-token'

interface RecentSearchShortcutProps {
  keyword: string
  icon?: ReactNode
  onClick: (e: MouseEvent<HTMLLIElement>) => void
  onRemoveClick: (e: MouseEvent) => void
}

const RecentSearchShortcut = (props: RecentSearchShortcutProps) => {
  return (
    <li
      className={`search__recent-search-shortcut ${css.root}`}
      onClick={props.onClick}
    >
      <div className={css.keywordContainer}>
        <div className={css.keywordWithIconContainer}>
          <IconContainerRecentSearch>
            {props.icon ? (
              <>{props.icon}</>
            ) : (
              <IconClockLine size={18} color={vars.$scale.color.gray600} />
            )}
          </IconContainerRecentSearch>
          <div className={css.keyword} role="button">
            {props.keyword}
          </div>
        </div>
        <button
          className={css.removeIconButton}
          aria-label="삭제"
          onClick={props.onRemoveClick}
        >
          <IconXmarkFill size={16} color={vars.$scale.color.gray600} />
        </button>
      </div>
    </li>
  )
}
export default RecentSearchShortcut
