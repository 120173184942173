import { AxiosError } from 'axios'
type SimpleNetworkErrorType = {
  message: 'Network Error' | 'Network request failed'
}
export const isNetworkError = (
  error: unknown
): error is AxiosError<SimpleNetworkErrorType> => {
  if (error instanceof AxiosError && error.message === 'Network Error') {
    return true
  }
  if (
    error instanceof AxiosError &&
    error.message === 'Network request failed'
  ) {
    return true
  }
  return false
}
