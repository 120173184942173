export const DELIVERY_FLAGS = {
  SAME_DAY: {
    LABEL: '당일배송',
    VALUE: 'sd',
  },
  FREE: {
    LABEL: '무료배송',
    VALUE: 'fd',
  },
}

export const BADGE_STYLE_TYPE_TO_PROPS = {
  [DELIVERY_FLAGS.SAME_DAY.VALUE]: {
    variant: 'accent',
    type: 'normal',
  },
  [DELIVERY_FLAGS.FREE.VALUE]: {
    variant: 'basic',
    type: 'normal',
  },
} as const
