import { SEARCH_WEBVIEW_ROUTES } from '@daangn/search-sdk'

export const getPortalPlugin = () => {
  if (typeof window === 'undefined') {
    return null
  }

  if (typeof window.VConsole === 'undefined') {
    return null
  }

  const portalPlugin = new window.VConsole.VConsolePlugin(
    'portalPlugin',
    'SearchPortal'
  )

  portalPlugin.on('renderTab', (callback: any) => {
    const page = SEARCH_WEBVIEW_ROUTES.Portal
    const menus = `
      <div>
        <a href="${page}" style="
          appearance: none;
          border: 0;
          color: var(--seed-scale-color-gray-900);
          text-decoration: none;
          cursor: pointer;
          display: flex;
          justify-content: center;
          padding: 0.8125rem 1rem;
          border-radius: 0.375rem;
          background: var(--seed-scale-color-gray-100);
        ">테스트 페이지로 이동</a>
      </div>
    `

    callback(menus)
  })

  /**
   * 하단 reload 버튼
   */
  portalPlugin.on('addTool', function (callback: any) {
    const button = {
      name: 'Reload',
      onClick: function () {
        window.location.reload()
      },
    }
    callback([button])
  })

  return portalPlugin
}
