import commonHeadersPlugin from '@daangn/plantae-plugin-common-headers'
import { karrotBridge } from '../../bridge/utils/bridgeMaker'

const plantaeCommonHeadersPlugin = () => {
  return commonHeadersPlugin({
    bridge: karrotBridge,
  })
}

export default plantaeCommonHeadersPlugin
