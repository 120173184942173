import { clientEnvironment, karrotBridge } from '../../bridge/utils/bridgeMaker'
import { stackDepthChangePlugin } from '@stackflow/plugin-stack-depth-change'
import type { Activity } from '@stackflow/core'

const handleDepthChange = (depth: number, activeActivities: Activity[]) => {
  if (clientEnvironment.isWeb) {
    return
  }

  const isDiscoverPage = Boolean(
    activeActivities.find(
      (activity) =>
        activity.name === 'HomeSearchDiscover' ||
        activity.name === 'NearbySearchDiscover' ||
        activity.name === 'SearchDiscover' ||
        activity.name === 'DeprecatedSearchDiscover'
    )
  )

  // [iOS] 디스커버 페이지 제외, 네이티브와 혼용된 페이지로 상단 네비바 사용
  if (isDiscoverPage) {
    karrotBridge.styleCurrentRouter({
      router: {
        backSwipable: true,
        scrollable: false,
      },
    })
    return
  }

  const isPriceFilterPage = Boolean(
    activeActivities.find(
      (activity) => activity.name === 'FleaMarketFilterPrice'
    )
  )

  // [iOS] 가격필터 일 경우, 키보드 닫기 버튼 사용하지 않음
  if (isPriceFilterPage && clientEnvironment.isIOS) {
    karrotBridge.styleCurrentRouter({
      router: {
        backSwipable: false,
        navbar: false,
        scrollable: false,
        IOS_ONLY_hideKeyboardAccessoryView: true,
      },
    })
    return
  }

  // 페이지 depth가 1이상인 경우, backswipe 사용 X
  if (depth > 1) {
    // [iOS] 검색 결과가 현재 화면일 경우, stack depth가 있더라도 scroll to top 사용 가능하도록 수정
    const isTopSearchResult = Boolean(
      activeActivities.find(
        (activity) =>
          activity.isTop &&
          (activity.name === 'HomeSearch' || activity.name === 'Search')
      )
    )

    if (isTopSearchResult && clientEnvironment.isIOS) {
      karrotBridge.styleCurrentRouter({
        router: {
          backSwipable: false,
          scrollable: true,
          navbar: false,
        },
      })
      return
    }

    karrotBridge.styleCurrentRouter({
      router: {
        backSwipable: false,
        scrollable: false,
        navbar: false,
      },
    })
    return
  }

  karrotBridge.styleCurrentRouter({
    router: {
      scrollable: true, // iOS 우측 상단 터치를 위해
      backSwipable: true,
      navbar: false,
    },
  })
}

export const searchStackDepthChangePlugin = () => {
  return stackDepthChangePlugin({
    onInit: ({ depth, activeActivities }) =>
      handleDepthChange(depth, activeActivities),
    onDepthChanged: ({ depth, activeActivities }) =>
      handleDepthChange(depth, activeActivities),
  })
}
