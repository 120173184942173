import { useMemo } from 'react'
import type { ComponentPropsWithoutRef } from 'react'
import { LazyImage } from '../lazy-load-image'
import type {
  AdaptiveImageResizableOptionType,
  AdaptiveImageFormatType,
} from './constants'
import * as css from './AdaptiveImage.css'

type Enumerate<
  N extends number,
  Acc extends number[] = [],
> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>

type ImageQualityRangeType = Enumerate<101>
type ImageSizeType = `${number}x${number}`

interface AdaptiveResizableOption {
  size: ImageSizeType
  quality: ImageQualityRangeType
  type: AdaptiveImageResizableOptionType
  format: AdaptiveImageFormatType
}

interface AdaptiveImageProps
  extends ComponentPropsWithoutRef<typeof LazyImage> {
  options?: AdaptiveResizableOption[]
}

const makeSourceSet = (
  src: string,
  { size, quality, type, format }: AdaptiveResizableOption
) => {
  try {
    const { origin, pathname, searchParams } = new URL(src)
    const baseImageUrl = `${origin}${pathname}`

    searchParams.set('s', size)
    searchParams.set('q', quality.toString())
    searchParams.set('t', type)
    searchParams.set('f', format)

    return {
      srcSet: `${baseImageUrl}?${searchParams.toString()}`,
      type: `image/${format}`,
    }
  } catch {
    return null
  }
}

const AdaptiveImage = (props: AdaptiveImageProps) => {
  const { options, src, ...lazyImageProps } = props

  const sources = useMemo(() => {
    if (!src || !options) {
      return []
    }

    return options.map((source) => makeSourceSet(src, source)).filter(Boolean)
  }, [options, src])

  return (
    <picture className={css.picture}>
      {sources.map((source, index) => {
        if (!source) {
          return null
        }

        return (
          <source
            key={`${source.srcSet}-${index}`}
            srcSet={source.srcSet}
            type={source.type}
          />
        )
      })}
      <LazyImage src={src} {...lazyImageProps} />
    </picture>
  )
}

export default AdaptiveImage
