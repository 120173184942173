export const PLACE_COMPONENT_TYPE = {
  PLACE: 'PLACE',
  PLACE_CREATE_BANNER: 'PLACE_CREATE_BANNER',
  UNKNOWN: 'UNKNOWN',
} as const

export const PLACE_COLLECTION_NAME = {
  BIZ_ACCOUNT: 'BIZ_ACCOUNT',
  POI: 'POI',
  LOCAL_PROFILE: 'LOCAL_PROFILE',
  UNKNOWN: 'UNKNOWN',
} as const

export type PlaceComponentNameType =
  (typeof PLACE_COLLECTION_NAME)[keyof typeof PLACE_COLLECTION_NAME]

export const MAX_DISPLAYED_PLACE_ARTICLE_COUNT = 5
