import type { Plugin } from 'plantae'

export default function plantaeDeviceIdentityPlugin({
  deviceIdentity,
}: {
  deviceIdentity: string
}): Plugin {
  return {
    name: 'plugin-device-identify',
    hooks: {
      beforeRequest: async (req) => {
        req.headers.set('x-device-identity', deviceIdentity)

        return req
      },
    },
  }
}
