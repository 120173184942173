import type { Plugin } from 'plantae'

type PlantaeSearchOriginPluginOptionsType = {
  origin?: string
}
export default function plantaeSearchOriginPlugin(
  options?: PlantaeSearchOriginPluginOptionsType
): Plugin {
  return {
    name: 'plugin-search-origin',
    hooks: {
      beforeRequest: async (req) => {
        req.headers.set('x-search-origin', options?.origin ?? 'search-webview')

        return req
      },
    },
  }
}
