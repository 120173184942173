export const getCurrentEnvironment = () => {
    var _a, _b, _c;
    if (typeof window === 'undefined') {
        throw new Error(`SearchBridge는 Node.js 환경을 지원하지 않아요.` +
            ` 혹시 SSR (Universal Rendering) 환경이시라면,` +
            ` SearchBridge 객체 생성이 Node.js 환경에서 이루어지고있는지 확인해주세요.`);
    }
    if (window.AndroidFunction) {
        return 'Android';
    }
    if ((_c = (_b = (_a = window.webkit) === null || _a === void 0 ? void 0 : _a.messageHandlers) === null || _b === void 0 ? void 0 : _b.searchHandler) === null || _c === void 0 ? void 0 : _c.postMessage) {
        return 'Cupertino';
    }
    return 'Web';
};
