import satisfies from 'semver/functions/satisfies'
import coerce from 'semver/functions/coerce'
import type { AppType } from '../context/FoundationProvider'

export const satisfyAndroidVersion = (
  app: Pick<AppType, 'os' | 'version'>,
  range: string
) => {
  if (app.os !== 'ANDROID') {
    return false
  }

  if (!app.version) {
    return false
  }

  return satisfies(app.version, range)
}

export const satisfyAndroidNativeOSVersion = (
  app: Pick<AppType, 'os' | 'osVersion'>,
  range: string
) => {
  if (app.os !== 'ANDROID') {
    return false
  }

  if (!app.osVersion) {
    return false
  }

  const forceVersion = coerce(app.osVersion)

  if (!forceVersion) {
    return false
  }

  return satisfies(forceVersion, range)
}

export const satisfyIosVersion = (
  app: Pick<AppType, 'os' | 'version'>,
  range: string
) => {
  if (app.os !== 'IOS') {
    return false
  }

  if (!app.version) {
    return false
  }

  return satisfies(app.version, range)
}

export const satisfyIosNativeOSVersion = (
  app: Pick<AppType, 'os' | 'osVersion'>,
  range: string
) => {
  if (app.os !== 'IOS') {
    return false
  }

  if (!app.osVersion) {
    return false
  }

  return satisfies(app.osVersion, range)
}
