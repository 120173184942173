export const FLEA_MARKET_BADGE_STYLE_TYPE = {
  CLOSED: 'closed',
  RESERVED: 'reserved',
} as const

export type FleaMarketBadgeStyleType =
  (typeof FLEA_MARKET_BADGE_STYLE_TYPE)[keyof typeof FLEA_MARKET_BADGE_STYLE_TYPE]

export const FLEA_MARKET_BADGE_STYLE_TYPE_TO_PROPS = {
  [FLEA_MARKET_BADGE_STYLE_TYPE.CLOSED]: {
    variant: 'basic',
    type: 'bold',
  },
  [FLEA_MARKET_BADGE_STYLE_TYPE.RESERVED]: {
    variant: 'success',
    type: 'bold',
  },
} as const
