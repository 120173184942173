import {
  IMPRESSION_EXPOSURE_TIME,
  IMPRESSION_THRESHOLD,
  IMPRESSION_COOL_TIME,
  LOG_STATUS,
} from '../common/constants'

import { isInViewport, isElementVisible } from '../common/utils'
import type {
  LogType,
  LoggerType,
  PageImpressionTrackerOptionsType,
  ImpressionObserverOptionsType,
  LogStatusType,
} from '../common/types'
import {
  clearPrevLogStatus,
  hasCameBackFromDisabledStatus,
} from '../common/enableImpression'

const satisfyImpressionTrackerSupport = () => {
  if (!Object.prototype.hasOwnProperty.call(window, 'IntersectionObserver')) {
    console.log('Intersection Observer API 미지원 브라우저')
    return false
  }
  if (!Object.prototype.hasOwnProperty.call(window, 'MutationObserver')) {
    console.log('Mutation Observer API 미지원 브라우저')
    return false
  }
  return true
}

/**
 * update logging status on given ELEMENT
 * @param el
 * @param logStatus
 */
const updateLogStatus = (el: HTMLElement, logStatus: LogStatusType) => {
  el.dataset.logStatus = logStatus
}
/**
 * trigger callback, if given ELEMENT is still in viewport after waiting given EXPOSURE TIME
 * @param el
 * @param threshold
 * @param exposureTime
 */
const setExposureTimeout = (
  callback: () => void,
  el: HTMLElement,
  threshold: number,
  exposureTime: number
) => {
  setTimeout(() => {
    const isStillInViewport =
      el && isInViewport(el, true, threshold) && isElementVisible(el)
    const isObservingStatus = ![
      LOG_STATUS.OBSERVED,
      LOG_STATUS.COOL,
      LOG_STATUS.DISABLE,
    ].includes(el.dataset.logStatus as LogStatusType)

    if (isStillInViewport && isObservingStatus) {
      callback()
    }
  }, exposureTime)
}
/**
 * trigger callback, after waiting given COOL TIME
 * @param callback
 * @param coolTime
 */
const setFreezeTimeout = (
  callback: () => void,
  coolTime: number = IMPRESSION_COOL_TIME
) => {
  setTimeout(() => {
    callback()
  }, coolTime)
}

/**
 * trigger logger with given LOG or from given ELEMENT dataset
 *
 */
const triggerLogger = (
  logger: LoggerType,
  {
    log,
    onPreLogging,
    onPostLogging,
  }: {
    log: LogType
    onPreLogging?: () => void
    onPostLogging?: () => void
  }
) => {
  onPreLogging?.()
  logger(log)
  onPostLogging?.()
}

const getLogFromDatasetAttribute = (el: HTMLElement) => {
  return {
    name: el.dataset.logName ?? '',
    params: {
      ...JSON.parse(el.dataset.logParams ?? ''),
    },
  }
}

/**
 * set intersection observer with given ELEMENT
 * @param io
 * @param el
 */
const setObserver = (io: IntersectionObserver, el: HTMLElement) => {
  io.unobserve(el)
  io.observe(el)
}

/**
 * create intersection observer with given onIntersect{In|Out} callback
 */
const createIntersectionTracker = ({
  threshold,
  onIntersectIn,
  onIntersectOut,
}: {
  threshold: number
  onIntersectIn: (el: HTMLElement) => void
  onIntersectOut: (el: HTMLElement) => void
}) => {
  return new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const { target, isIntersecting } = entry
        const observeTarget = target as HTMLElement
        if (!observeTarget.dataset?.logStatus) return
        isIntersecting
          ? onIntersectIn(observeTarget)
          : onIntersectOut(observeTarget)
      })
    },
    { threshold }
  )
}

const createGlobalLogStatusTracker = ({
  onInit,
  onObserved,
  onCool,
}: {
  onInit: (el: HTMLElement) => void
  onObserved: (el: HTMLElement) => void
  onCool: (el: HTMLElement) => void
}) => {
  const mo = new MutationObserver((mutationRecords) => {
    mutationRecords.forEach((mutationRecord) => {
      if ('attributes' === mutationRecord.type) {
        const el = mutationRecord.target as HTMLElement
        const logStatus = el.dataset.logStatus

        switch (logStatus) {
          case LOG_STATUS.INIT: {
            onInit(el)
            break
          }
          case LOG_STATUS.OBSERVED: {
            onObserved(el)
            break
          }
          case LOG_STATUS.COOL: {
            onCool(el)
            break
          }
          case LOG_STATUS.OBSERVING:
          default:
        }
      } else if ('childList' === mutationRecord.type) {
        const els: NodeListOf<HTMLElement> =
          document.querySelectorAll('[data-log-name]')
        els.forEach((el) => {
          el.dataset.logStatus ?? updateLogStatus(el, LOG_STATUS.INIT)
        })
      }
    })
  })
  const observerRootEl = document.querySelector('body')
  if (observerRootEl && mo) {
    mo.observe(observerRootEl, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['data-log-status'],
      attributeOldValue: true,
    })
  }

  return mo
}

/**
 * create mutation observer which tracks log status
 */
const createLogStatusTracker = ({
  onInit,
  onObserved,
  onCool,
}: {
  onInit: (el: HTMLElement) => void
  onObserved: (el: HTMLElement) => void
  onCool: (el: HTMLElement) => void
}) => {
  return new MutationObserver((mutationRecords) => {
    mutationRecords.forEach((mutationRecord) => {
      const targetEl = mutationRecord.target as HTMLElement
      const logStatus = targetEl.dataset.logStatus

      switch (logStatus) {
        case LOG_STATUS.INIT: {
          onInit(targetEl)
          break
        }
        case LOG_STATUS.OBSERVED: {
          onObserved(targetEl)
          break
        }
        case LOG_STATUS.COOL: {
          onCool(targetEl)
          break
        }
        case LOG_STATUS.OBSERVING:
        default:
      }
    })
  })
}
/**
 * Impression 로깅용 Intersection/Mutation Observer 인스턴스 생성 함수
 *
 * @param logger
 * @param options
 * @returns
 */
const createImpressionObserver = (
  logger: LoggerType,
  options: ImpressionObserverOptionsType,
  log?: LogType,
  DEV_ONLY_showLogData?: boolean
) => {
  if (!satisfyImpressionTrackerSupport()) return

  const intersectionTracker = createIntersectionTracker({
    threshold: options.threshold,
    onIntersectIn: (el) =>
      setExposureTimeout(
        () => updateLogStatus(el, LOG_STATUS.OBSERVED),
        el,
        options.threshold,
        options.exposureTime
      ),
    onIntersectOut: (el) => {
      if (LOG_STATUS.OBSERVED === el.dataset.logStatus) {
        updateLogStatus(el, LOG_STATUS.COOL)
      }
    },
  })

  const logStatusTrackerCallbacks = {
    onInit: (el: HTMLElement) => {
      setObserver(intersectionTracker, el)
      updateLogStatus(el, LOG_STATUS.OBSERVING)
    },
    onObserved: (el: HTMLElement) => {
      if (hasCameBackFromDisabledStatus(el)) {
        clearPrevLogStatus(el)
        return
      }

      triggerLogger(logger, {
        log: options.manual ? (log as LogType) : getLogFromDatasetAttribute(el),
        onPreLogging: options.onPreLogging,
        onPostLogging: options.onPostLogging,
      })
    },
    onCool: (el: HTMLElement) => {
      setFreezeTimeout(
        () => updateLogStatus(el, LOG_STATUS.INIT),
        options.coolTime
      )
    },
  }

  const logStatusTracker = options.manual
    ? createLogStatusTracker({ ...logStatusTrackerCallbacks })
    : createGlobalLogStatusTracker({ ...logStatusTrackerCallbacks })

  return {
    disconnect: () => {
      intersectionTracker && intersectionTracker.disconnect()
      logStatusTracker && logStatusTracker.disconnect()
    },
    observe: (el: HTMLElement) => {
      if (el && options.manual) {
        if (DEV_ONLY_showLogData) {
          el.dataset.devOnlyShowLog = JSON.stringify((log as LogType).params)
        }
        logStatusTracker.observe(el, {
          childList: true,
          attributes: true,
          attributeFilter: ['data-log-status'],
        })
        updateLogStatus(el, LOG_STATUS.INIT)
      }
    },
    unobserve: (el: HTMLElement) => {
      if (!el) return
      if (DEV_ONLY_showLogData) {
        delete el.dataset.devOnlyShowLog
      }
      intersectionTracker.unobserve(el)
      logStatusTracker && logStatusTracker.disconnect()
      updateLogStatus(el, LOG_STATUS.DISCONNECT)
    },
  }
}

/**
 * viewport에 노출된 로깅대상 영역 수집처리 트래커
 * @param logger
 * @param options
 * @returns
 */
const pageImpressionTracker = (
  logger: LoggerType,
  options: PageImpressionTrackerOptionsType,
  log?: LogType,
  DEV_ONLY_showLogData?: boolean
) => {
  const trackerOptions = {
    threshold: options?.threshold || IMPRESSION_THRESHOLD,
    exposureTime: options?.exposureTime || IMPRESSION_EXPOSURE_TIME,
    coolTime: options?.coolTime || IMPRESSION_COOL_TIME,
    onPreLogging: options?.onPreLogging,
    onPostLogging: options?.onPostLogging,
    manual: options?.manual ?? false,
  }
  const impressionObserver = createImpressionObserver(
    logger,
    trackerOptions,
    log,
    DEV_ONLY_showLogData
  )

  return impressionObserver
}
export default pageImpressionTracker
