import type { PropsWithChildren } from 'react'
import type {
  BadgeSize,
  BadgeType,
  BadgeVariant,
  BadgeShape,
} from './Badge.css'
import { badge } from './Badge.css'
import classNames from 'classnames'

interface BadgeProps {
  className?: string
  variant: BadgeVariant
  size: BadgeSize
  type: BadgeType
  shape: BadgeShape
}

/**
 * NOTE: 디자인 시스템에 컴포넌트가 작성되기 전까지 임시로 사용해요.
 * https://www.figma.com/file/ty7UxJ61CVPeVU2Gf1LJGQ/App-Components?node-id=21145%3A39275
 */
const Badge = (props: PropsWithChildren<BadgeProps>) => {
  return (
    <div
      className={classNames(
        badge({
          variant: props.variant,
          size: props.size,
          type: props.type,
          shape: props.shape,
        }),
        props.className
      )}
    >
      {props.children}
    </div>
  )
}

export default Badge
