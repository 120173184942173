/**
 * 개발용 karrotBridge.getAppInfo()
 */
export const DEFAULT_APP = process.env.REACT_APP_DEFAULT_USER_AGENT
  ? Object.freeze({
      userAgent: process.env.REACT_APP_DEFAULT_USER_AGENT,
      deviceIdentity: process.env.REACT_APP_DEFAULT_DEVICE_IDENTITY ?? '',
    })
  : undefined

/**
 * 개발용 karrotBridge.getUserInfo()
 */
export const DEFAULT_USER = process.env.REACT_APP_DEFAULT_USER_ID
  ? Object.freeze({
      id: process.env.REACT_APP_DEFAULT_USER_ID
        ? Number(process.env.REACT_APP_DEFAULT_USER_ID)
        : undefined,
      nickname: process.env.REACT_APP_DEFAULT_USER_NICKNAME ?? undefined,
      authToken: process.env.REACT_APP_DEFAULT_AUTH_TOKEN ?? undefined,
    })
  : undefined

/**
 * 개발용 karrotBridge.getRegionInfo()
 */
export const DEFAULT_REGION = (() => {
  if (!process.env.REACT_APP_DEFAULT_REGION_ID) {
    return undefined
  }

  const centerCoordinate =
    process.env.REACT_APP_DEFAULT_REGION_CENTER_COORDINATES?.split(',')
  const [latitude, longitude] = centerCoordinate ?? [undefined, undefined]

  return Object.freeze({
    id: process.env.REACT_APP_DEFAULT_REGION_ID
      ? Number(process.env.REACT_APP_DEFAULT_REGION_ID)
      : undefined,
    name: process.env.REACT_APP_DEFAULT_REGION_NAME ?? undefined,
    centerCoordinates:
      latitude && longitude
        ? {
            latitude: Number(latitude),
            longitude: Number(longitude),
          }
        : undefined,
  })
})()

/**
 * 개발용 karrotBridge.getCurrentPosition()
 */
export const DEFAULT_GEOLOCATION = (() => {
  if (!process.env.REACT_APP_DEFAULT_GEOLOCATION) {
    return undefined
  }

  const [latitude, longitude] =
    process.env.REACT_APP_DEFAULT_GEOLOCATION.split(',')

  return Object.freeze({
    currentPosition: {
      position: {
        latitude: Number(latitude),
        longitude: Number(longitude),
      },
    },
  })
})()
