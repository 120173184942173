import type { ErrorInfo, ReactNode } from 'react'
import { Component } from 'react'
import { captureException } from '../../utils/captureException'
import BridgeTimeoutError from '../../../error/class/BridgeTimeoutError'
import MaintenanceError from '../../../error/class/MaintenanceError'
import { MaintenanceErrorPanel } from './MaintenanceErrorPanel'

interface ErrorBoundaryProps {
  children?: ReactNode
  fallback: ReactNode
}

class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  { hasError: boolean; maintenanceErrorMessage: string | null }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, maintenanceErrorMessage: null }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error instanceof BridgeTimeoutError) {
      return
    }

    if (error instanceof MaintenanceError) {
      this.setState({ maintenanceErrorMessage: error.message })
      return
    }

    captureException(error, { extra: errorInfo })
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // NOTE: maintenance 에러가 발생했을 때, 에러메세지를 노출해요.
      if (this.state.maintenanceErrorMessage) {
        return (
          <MaintenanceErrorPanel message={this.state.maintenanceErrorMessage} />
        )
      }

      // You can render any custom fallback UI
      return this.props.fallback
    }

    return this.props.children
  }
}

export default ErrorBoundary
