import authPlugin from '@daangn/plantae-plugin-auth'
import { clientEnvironment, karrotBridge } from '../../bridge/utils/bridgeMaker'

const plantaeAuthPlugin = ({
  fallbackAuthToken,
}: {
  fallbackAuthToken: string
}) => {
  const isApp = clientEnvironment.isAndroid || clientEnvironment.isIOS
  return authPlugin({
    bridge: karrotBridge,
    options: {
      fallbackAuthToken: isApp || {
        value: fallbackAuthToken ?? undefined, //local 환경 테스트
      },
    },
  })
}

export default plantaeAuthPlugin
