import type { ReactNode } from 'react'
import { Suspense } from 'react'
import SearchDiscoverSkeleton from '../search-discover/SearchDiscoverSkeleton'
import PageSearchResultSkeleton from '../../../_pages/PageSearchResultSkeleton'
import {
  executeByEnvironment,
  karrotBridge,
} from '../../../bridge/utils/bridgeMaker'
import PageHomeSearchSkeleton from '../../../_pages/PageHomeSearchSkeleton'

interface EntryPointSkeletonProps {
  children: ReactNode
}

const EntryPointSkeleton = (props: EntryPointSkeletonProps) => {
  // @deprecated 로직
  const isDeprecatedSearchDiscoverPath =
    window.location.pathname.startsWith('/search/discover')
  if (isDeprecatedSearchDiscoverPath) {
    return (
      <Suspense fallback={<SearchDiscoverSkeleton />}>
        {props.children}
      </Suspense>
    )
  }

  const params = new URLSearchParams(document.location.search)
  const queryValue = params.get('query')
  const isDeprecatedSearchResultPath =
    window.location.pathname.startsWith('/search')
  // @deprecated 로직
  if (isDeprecatedSearchResultPath && queryValue) {
    return (
      <Suspense
        fallback={
          <PageSearchResultSkeleton
            query={queryValue}
            onBackClick={() => {
              executeByEnvironment({
                onApp: () => karrotBridge.closeRouter({}),
                onWeb: () => window.history.back(),
              })
            }}
          />
        }
      >
        {props.children}
      </Suspense>
    )
  }

  const isHomePath = window.location.pathname.startsWith('/home')
  if (isHomePath) {
    return (
      <Suspense
        fallback={
          <PageHomeSearchSkeleton
            query={queryValue || ''}
            onBackClick={() => {
              executeByEnvironment({
                onApp: () => karrotBridge.closeRouter({}),
                onWeb: () => window.history.back(),
              })
            }}
          />
        }
      >
        {props.children}
      </Suspense>
    )
  }
  return <Suspense fallback={null}>{props.children}</Suspense>
}

export default EntryPointSkeleton
