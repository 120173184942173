import * as Sentry from '@sentry/react'
import { isMaintenanceError } from '../../error/utils/getMaintenanceError'
import { isClientTimeoutError } from '../../error/utils/isClientTimeoutError'
import { isNetworkError } from '../../error/utils/isNetworkError'
import { isAuthorizationError } from '../../error/utils/isAuthorizationError'

export function captureException(error?: Error | null, errorInfo?: any) {
  if (!error) {
    return
  }

  if (isNetworkError(error)) {
    return
  }

  if (isClientTimeoutError(error)) {
    return
  }

  // NOTE: 정기점검 에러로 인한 로그는 수집하지 않아요.
  if (isMaintenanceError(error)) {
    return
  }

  // NOTE: plantae-plugin-auth 내에서 정의한 에러는 디버깅을 위해 따로 명시 후 수집처리
  if (isAuthorizationError(error)) {
    Sentry.captureException(error, { extra: errorInfo })
  }

  Sentry.captureException(error, { extra: errorInfo })
}
