import { useMemo } from 'react'
import { useGlobalStore } from '../../_app/stores/GlobalStoreProvider'

export const useExperimentSegment = () => {
  const experimentState = useGlobalStore((state) => state.experiment.state)

  const experimentSegmentObject = useMemo(() => {
    const experimentSegments = experimentState.experimentSegments
    return experimentSegments.reduce<any>((acc, cur) => {
      acc[cur.segmentKey] = cur.segmentName
      return acc
    }, {})
  }, [experimentState.experimentSegments])

  const searchExperimentSegmentArray = useMemo(() => {
    return experimentState.experimentSegments
  }, [experimentState.experimentSegments])

  const experimentHeaderSegmentReferrer = useMemo(() => {
    return {
      headerSegmentKey: experimentState.headerSegmentKey ?? '',
      headerSegmentValue: experimentState.headerSegmentValue ?? '',
    }
  }, [experimentState.headerSegmentKey, experimentState.headerSegmentValue])

  return useMemo(
    () => ({
      experimentSegmentObject: experimentSegmentObject,
      searchExperimentSegmentArray: searchExperimentSegmentArray,
      experimentHeaderSegmentReferrer: experimentHeaderSegmentReferrer,
    }),
    [
      experimentSegmentObject,
      searchExperimentSegmentArray,
      experimentHeaderSegmentReferrer,
    ]
  )
}
