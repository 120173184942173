import { useMemo } from 'react'
import { Skeleton } from '../skeleton'
import { container, body, header, removeAll, title } from './RecentSearch.css'
import { item } from './RecentSearchSkeleton.css'

export interface RecentSearchSkeletonProps {
  className?: string
}

const IconSkeleton = () => {
  return (
    <Skeleton
      width="1.75rem"
      height="1.75rem"
      border={{ radius: '0.875rem' }}
      margin={{
        left: '1rem',
      }}
    />
  )
}

const KeywordSkeleton = () => {
  return (
    <Skeleton
      width="10rem"
      height="1.75rem"
      border={{ radius: '0.875rem' }}
      margin={{
        left: '1rem',
      }}
    />
  )
}

const RecentSearchSkeleton = (props: RecentSearchSkeletonProps) => {
  const skeletonList = useMemo(() => new Array(5).fill(null), [])
  return (
    <article className={`${container} ${props.className ?? ''}`}>
      <header className={header}>
        <h2 className={title} a11y-label="최근 검색">
          최근 검색
        </h2>
        <span className={removeAll} role="button">
          전체 삭제
        </span>
      </header>
      <ol className={body} style={{ display: 'flex' }}>
        {skeletonList.map((_, index) => (
          <div key={index} className={item}>
            <IconSkeleton />
            <KeywordSkeleton />
          </div>
        ))}
      </ol>
    </article>
  )
}

export default RecentSearchSkeleton
