import type { ReactNode } from 'react'
import { useExperimentContext } from './AppInitializeProvider'
import { GlobalStoreProvider } from '../stores/GlobalStoreProvider'

interface GlobalMutableStateProviderProps {
  children: ReactNode
}

const GlobalMutableStateProvider = (props: GlobalMutableStateProviderProps) => {
  const experimentState = useExperimentContext()

  return (
    <GlobalStoreProvider
      initGlobalState={{
        experiment: {
          state: experimentState,
        },
      }}
    >
      {props.children}
    </GlobalStoreProvider>
  )
}

export default GlobalMutableStateProvider
