import { useMemo } from 'react'
import { makeNumberToKoreanSplitUnit } from '../utils/splitUnit'
import { commaNumber } from '../utils/helpers'

export const usePriceWithCurrency = (price: number | null) => {
  const priceWithCurrency = useMemo(() => {
    if (price === null) {
      return null
    }

    if (price < 1_000_000) {
      // 100만원 이하일때 그대로 표시
      return `${commaNumber(price)}원`
    }

    return `${makeNumberToKoreanSplitUnit(price)}원`
  }, [price])

  return priceWithCurrency
}
