import 'src/packages/flea-market/CardFleaMarket.css.ts.vanilla.css?source=#H4sIAAAAAAAAA51WUW+bMBB+769AlSatD0bQNmlLtadKm/awpz1OU3XgC1xjsGU7SdNp/322Q4Ek0KVVFAVs7rv7vvvOJH4Azb8KhB+gl2gfC9lYoAb142O61YLMSxL9OYsiTkYJ2GbRQuDzvVsopJA6i9agPzNmEDkzBQhkYZ2VGrbsLkku7s/+nsUHOaiGEh+OEqUh0Ya4rbJoHt/MNNY+U4VUVnZvKZeao8uequfISEH8zTpAqArYzBfjQqFYllquGs5GKGANjaWijVagULM+4hDA0AtmTok16oMdJQ1Zkk0WzZJP/uv3a9AlNUzv6KQtFx+9EHKTRRVxjo1f68M1CrC0xp4108BpZbIoia86QXxX3Ir7wMrKd4k++mC3f3l6U2T+hIVlC7JDTY5q3vGeKLDP+8vo4sv5+e999zWywbHg2rHqYq/GHbvTKH29ZJy0qzeo7Nq9qt9QvnZtazVIxtJbsqKv/TrkXzitW4eMOcxulXTuVNWW5ZJvWZ0yjeVKgGZdZPBbuNu0cn8EaRcbsITrPas+hjWI3WGhta6zRkFBTfl+uL3wi8F85NJaWXuvJPPLV3N13XR08iVZ91Ro6uttqK4QUKssuvQbazKUkyDrYsK1wOHzLpxJTdh4IVC7YkH4fYvPlvUjiUKQMmSmBnXj7M1yjbDMovDjDhsx5hBjwa5MZ5HZuEVbBaxUnn7asp9C+waqA5wHwP0TJoknARYa8WcF2knfQdyM1wSCyoaRxdrNb+EE2w32wN5JfNsfREO35lLwsexKU9GPy+3huLwbrwDlZ7aX926cytPKWFpsmX/Jhc6HcwAbfqj91CHaJuryQMhzwqtwniQXx6qlx/b+j+oTDT6Y7DTxL5wTys8EGMuKigQf888bGN+LAU5+otw9jwl67Rmbxr0yHafB2qEK/c4Jrbje/Sv5B/ff50b5CAAA';
export var caption = 'CardFleaMarket_caption__1yrlisza';
export var captionIcon = 'CardFleaMarket_captionIcon__1yrliszb';
export var captions = 'CardFleaMarket_captions__1yrlisz9';
export var container = 'CardFleaMarket_container__1yrlisz0';
export var freeSharing = 'CardFleaMarket_freeSharing__1yrlisz7';
export var image = 'CardFleaMarket_image__1yrlisz2';
export var imageContainer = 'CardFleaMarket_imageContainer__1yrlisz1';
export var main = 'CardFleaMarket_main__1yrlisz3';
export var price = 'CardFleaMarket_price__1yrlisz8';
export var status = 'CardFleaMarket_status__1yrlisz5';
export var statusGap = 'CardFleaMarket_statusGap__1yrlisz6';
export var title = 'CardFleaMarket_title__1yrlisz4';