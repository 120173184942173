import type { MouseEvent } from 'react'
import { Container, Name } from './Shortcut.css'
import { IconChevronRightLine } from '@daangn/react-monochrome-icon'
import { vars } from '@seed-design/design-token'

export interface ShortcutAutoCompleteKeywordType {
  readonly name: string
  readonly targetUri: string
}

interface ShortcutProps {
  query: string
  index: number
  keyword: ShortcutAutoCompleteKeywordType
  onClick: (
    e: MouseEvent<HTMLButtonElement>,
    info: {
      keyword: ShortcutAutoCompleteKeywordType
      query: string
      index: number
    }
  ) => void
}

const Shortcut = (props: ShortcutProps) => {
  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    props.onClick(e, {
      keyword: props.keyword,
      query: props.query,
      index: props.index,
    })
  }

  return (
    <button className={Container} onClick={onClick}>
      <strong className={Name}>{props.keyword.name}</strong>
      <IconChevronRightLine size={16} color={vars.$scale.color.gray900} />
    </button>
  )
}

export default Shortcut
