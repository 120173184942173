export const SALARY_TYPE = {
  MONTHLY: {
    ID: 'MONTHLY',
    LABEL: '월급',
  },
  DAILY: {
    ID: 'DAILY',
    LABEL: '일급',
  },
  HOURLY: {
    ID: 'HOURLY',
    LABEL: '시급',
  },
  PER_CASE: {
    ID: 'PER_CASE',
    LABEL: '건당',
  },
  UNKNOWN: {
    ID: 'UNKNOWN',
    LABEL: '',
  },
} as const

export type SalaryTypeIdsType =
  (typeof SALARY_TYPE)[keyof typeof SALARY_TYPE]['ID']

export const WORK_DAYS = {
  SUN: {
    ID: 'SUN',
    LABEL: '일',
    INDEX: 6,
  },
  MON: {
    ID: 'MON',
    LABEL: '월',
    INDEX: 0,
  },
  TUE: {
    ID: 'TUE',
    LABEL: '화',
    INDEX: 1,
  },
  WED: {
    ID: 'WED',
    LABEL: '수',
    INDEX: 2,
  },
  THU: {
    ID: 'THU',
    LABEL: '목',
    INDEX: 3,
  },
  FRI: {
    ID: 'FRI',
    LABEL: '금',
    INDEX: 4,
  },
  SAT: {
    ID: 'SAT',
    LABEL: '토',
    INDEX: 5,
  },
} as const

export type WorkDaysIdsType = (typeof WORK_DAYS)[keyof typeof WORK_DAYS]['ID']

export const FALLBACK_WORK_TIME_AT = '00:00'
