import 'src/packages/navbar/SearchInputNavbar.css.ts.vanilla.css?source=#H4sIAAAAAAAAA+VYy27bMBC85ysEFAHiAwXZsvJQTomBALkUBdpLTwEt0RIbihQoKo5b9N9LUaL1tinHOfUS6zmzOxruLmN/R5AH8TNNc/EVvq0hf1kxKiCmiL+80E20XW4d68+FZYU4Swnc+daGoPd7eWGLQxH71txxLovTNQxeI85yGoKAEcZ96w3yKwAyhEL5J4FU4KC8BVKYIg5CtIE5EbP7i78Xdj+ObzBCjxK0F898OB5IcEQBFijJfCtAVCBeXE5hGGIa+ZZjObbHUTJCp44f2btmWQyzHE4zgARVOUYc7oAUZ9YJYm7fFFGocG7KeCQq46FUhMMQ5zJ6x3b3twSHNMMCM+r3yC3XcZLMNCEfBgK/IZXXtDQWVRoHYzkSyQrSABEdjDusbso0PFxnjOQCNZ1mzxdalRjhKBbta7XI9mLRkm/DeOKXhwQK9PMKeM6lyijIeVZknzKsDQO2aP2KBRAwBbFkIQWTVklhpJBLe3UFYSkMsNgd/yYdJZqfpcJQKYzhOybgUlZRZP3yQEPOcKjZlopGsNS3vHLdci2kd2xxdKFXuVzFAlOmwb0afOm1wZdG2D84giKR2mrE6//eJn1JPsMvNcuYcW5GjTPxy47b53bUPkcoNMCdAuj0pY1sHyDDv5HGsSwiuwmoreE2yvB78ahyR1WS5SXVXNJUchep+BZlFNVFW2rf7gv1AywXBVV9waDU3lWldoq9Anm0v/dl6d0+PT0Z6eX78msEKGZEJqLEM4jQUxEaoeskMvWkbPgB47BwZ3tZl/Kcghgoc4F1LsT5QDnK5FiSfRLqySKMrUyocBpVzbt21YBhe9VPdW6G3luW6y7+0r1t4evzQ/gPcv1rwEABFrVcrr52OedIhiGL21GoMTHCnhhFaPNWvO7iWKdrU/QUQV2SsrloGne5LA+MeHoFfvNxeY6X82hQp+6PmVImtT0ekaymMhGt2A48B4z2tgR4eE74YNtuhOuOjepVMzSu7IZ5jX23X2WPPHVPMIHobGPGAc6eTV576ZmMS5MYTt8FeUa7oLEgV4Rl6FG1lJ59yaQd7QddPWzgky11MK+zmajBosGTsk4W490GJphIZExjxLFoDmf7YW1gPhsa6hrj4tD/L8QuZdIMabwDBK4RWci+HuUEcrB/cbaH2VYj5glA5auz3rQ6DarxagmFhDQMyAq1i7o2Ea31tgI8d8X9jOLatM5YtaMGY8OhxjTI0as9bOLc8A8PDD1tnRMAAA==';
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var BackIconContainer = _7a468({defaultClassName:'SearchInputNavbar_BackIconContainer__nfgw4wi',variantClassNames:{platform:{Android:'SearchInputNavbar_BackIconContainer_platform_Android__nfgw4wj',Cupertino:'SearchInputNavbar_BackIconContainer_platform_Cupertino__nfgw4wk'}},defaultVariants:{},compoundVariants:[]});
export var CloseButton = _7a468({defaultClassName:'SearchInputNavbar_CloseButton__nfgw4wm',variantClassNames:{platform:{Android:'SearchInputNavbar_CloseButton_platform_Android__nfgw4wn',Cupertino:'SearchInputNavbar_CloseButton_platform_Cupertino__nfgw4wo'}},defaultVariants:{},compoundVariants:[]});
export var CloseButtonContainer = 'SearchInputNavbar_CloseButtonContainer__nfgw4wl';
export var Container = 'SearchInputNavbar_Container__nfgw4w0';
export var Input = _7a468({defaultClassName:'SearchInputNavbar_Input__nfgw4w9',variantClassNames:{platform:{Android:'SearchInputNavbar_Input_platform_Android__nfgw4wa',Cupertino:'SearchInputNavbar_Input_platform_Cupertino__nfgw4wb'}},defaultVariants:{},compoundVariants:[]});
export var InputArea = _7a468({defaultClassName:'SearchInputNavbar_InputArea__nfgw4wc',variantClassNames:{platform:{Android:'SearchInputNavbar_InputArea_platform_Android__nfgw4wd',Cupertino:'SearchInputNavbar_InputArea_platform_Cupertino__nfgw4we'}},defaultVariants:{},compoundVariants:[]});
export var InputAreaTreatment = _7a468({defaultClassName:'SearchInputNavbar_InputAreaTreatment__nfgw4wf',variantClassNames:{platform:{Android:'SearchInputNavbar_InputAreaTreatment_platform_Android__nfgw4wg',Cupertino:'SearchInputNavbar_InputAreaTreatment_platform_Cupertino__nfgw4wh'}},defaultVariants:{},compoundVariants:[]});
export var InputBox = 'SearchInputNavbar_InputBox__nfgw4w2';
export var InputCancel = _7a468({defaultClassName:'SearchInputNavbar_InputCancel__nfgw4w3',variantClassNames:{platform:{Android:'SearchInputNavbar_InputCancel_platform_Android__nfgw4w4',Cupertino:'SearchInputNavbar_InputCancel_platform_Cupertino__nfgw4w5'}},defaultVariants:{},compoundVariants:[]});
export var InputCancelTreatment = _7a468({defaultClassName:'SearchInputNavbar_InputCancelTreatment__nfgw4w6',variantClassNames:{platform:{Android:'SearchInputNavbar_InputCancelTreatment_platform_Android__nfgw4w7',Cupertino:'SearchInputNavbar_InputCancelTreatment_platform_Cupertino__nfgw4w8'}},defaultVariants:{},compoundVariants:[]});
export var PageBackContainer = 'SearchInputNavbar_PageBackContainer__nfgw4w1';