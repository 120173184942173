import { IconXmarkFill } from '@daangn/react-monochrome-icon'
import {
  Category,
  CategoryClose,
  CategoryContainer,
  CategoryText,
} from './SearchInputCategoryLabel.css'
import type { MouseEvent } from 'react'
import { vars } from '@seed-design/design-token'

interface SearchInputCategoryLabelProps {
  name: string
  onRemove?: (e: MouseEvent<HTMLDivElement>) => void
}

const SearchInputCategoryLabel = (props: SearchInputCategoryLabelProps) => {
  return (
    <div className={CategoryContainer}>
      <label className={Category}>
        <span
          className={CategoryText}
          role="search"
          aria-label={`카테고리 ${props.name}`}
        >
          {props.name}
        </span>
        <div
          role="button"
          aria-label={'선택된 카테고리 지우기'}
          className={CategoryClose}
          onClick={(e) => {
            e.stopPropagation()
            props.onRemove?.(e)
          }}
        >
          <IconXmarkFill size={12} color={vars.$scale.color.gray900} />
        </div>
      </label>
    </div>
  )
}

export default SearchInputCategoryLabel
