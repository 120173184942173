import { Skeleton } from '../skeleton'
import { Body, Container, Header, Title } from './HotKeywords.css'
import { LABEL } from './constants'

export interface HotKeywordsSkeletonProps {
  className?: string
}

const HotKeywordsSkeleton = (props: HotKeywordsSkeletonProps) => {
  return (
    <article className={`${Container} ${props.className ?? ''}`}>
      <header className={Header}>
        <h2 className={Title} a11y-label={LABEL}>
          {LABEL}
        </h2>
      </header>
      <ol className={Body} style={{ display: 'flex' }}>
        <Skeleton
          width="3.5rem"
          height="1.975rem"
          border={{ radius: '1rem' }}
          margin={{
            left: '1rem',

            right: '0.5rem',
          }}
        />
        <Skeleton
          width="3.2rem"
          height="1.975rem"
          border={{ radius: '1rem' }}
          margin={{
            right: '0.5rem',
          }}
        />
        <Skeleton
          width="3.5rem"
          height="1.975rem"
          border={{ radius: '1rem' }}
          margin={{
            right: '0.5rem',
          }}
        />
        <Skeleton
          width="3.7rem"
          height="1.975rem"
          border={{ radius: '1rem' }}
          margin={{
            right: '0.5rem',
          }}
        />
      </ol>
    </article>
  )
}

export default HotKeywordsSkeleton
