import { LOG_STATUS } from './constants'
/**
 * disable impression logging
 * @param el
 */
export const disableImpression = (el: HTMLElement) => {
  if (!el) return
  const currentLogStatus = el.dataset['logStatus']
  if (currentLogStatus !== 'disable') {
    el.dataset['prevLogStatus'] = currentLogStatus
    el.dataset['logStatus'] = LOG_STATUS.DISABLE
  }
}
