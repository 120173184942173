/**
 * Custom User-Agent Spec.
 * https://www.notion.so/daangn/Custom-User-Agent-20c6ccb681104319b39a006dba4acd00
 */
const USER_AGENT_REGEX =
  /(?<customUserAgent>TowneersApp\/(?<versionName>\d+\.\d+\.\d+)\s\((?<versionCode>\d+);\s*(?<osType>iOS|Android)\s(?<osVersion>[^;]+);\s*(?<appType>\w+);\s*(?<buildType>\w+)\))/

const BUILD_TYPES = ['debug', 'release'] as const
type BuildTypes = (typeof BUILD_TYPES)[number]

export const extractAppBuildType = (userAgent: string) => {
  const result = userAgent.match(USER_AGENT_REGEX)

  if (!result || !result.groups) {
    return null
  }

  const { buildType } = result.groups

  if (!BUILD_TYPES.some((type) => type === buildType)) {
    return null
  }

  return buildType as BuildTypes
}

export const appBuildType = (userAgent: string) => {
  const type = extractAppBuildType(userAgent)
  return {
    isDebugApp: 'debug' === type,
    isReleaseApp: 'release' === type,
  }
}
