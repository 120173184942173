import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'
import type {
  LoggerType,
  PageImpressionTrackerOptionsType,
} from '../../../core/common/types'
import VisualiseToggleButton from '../Visualise/VisualiseToggleButton'
import { dummyLogger } from '../../../core/common/utils'

interface ImpressionContextType {
  impressionOptions: PageImpressionTrackerOptionsType
  logger: LoggerType
  initialized: boolean
  DEV_ONLY_showLogData: boolean
}
const ImpressionContext = createContext<ImpressionContextType>({
  impressionOptions: {},
  logger: dummyLogger,
  initialized: false,
  DEV_ONLY_showLogData: false,
})

interface ImpressionRootProps extends ImpressionContextType {
  DEV_ONLY_show_debug_overlay: boolean
  children: ReactNode
}
export const useImpression = () => {
  const impressionContext = useContext(ImpressionContext)
  return {
    ...impressionContext,
  }
}

export const ImpressionRoot = (props: ImpressionRootProps) => {
  return (
    <ImpressionContext.Provider
      value={{
        impressionOptions: { ...props.impressionOptions, manual: true },
        logger: props.logger,
        initialized: props.initialized,
        DEV_ONLY_showLogData: props.DEV_ONLY_showLogData,
      }}
    >
      {props.children}
      {props.DEV_ONLY_show_debug_overlay ? <VisualiseToggleButton /> : null}
    </ImpressionContext.Provider>
  )
}
