import type { StateCreatorWithDevtools } from '../../__development/zustand'

export interface LastOpenedSchemeTargetSliceType {
  lastOpenedSchemeTargetSlice: {
    schemeTarget: string | null
    reset: () => void
    setLastOpenedSchemeTargetState: (args: { schemeTarget: string }) => void
  }
}

export const lastOpenedSchemeTargetSlice: StateCreatorWithDevtools<
  LastOpenedSchemeTargetSliceType
> = (set) => ({
  lastOpenedSchemeTargetSlice: {
    schemeTarget: null,

    reset: () => {
      set((state) => {
        return {
          lastOpenedSchemeTargetSlice: {
            ...state.lastOpenedSchemeTargetSlice,
            schemeTarget: null,
          },
        }
      })
    },

    setLastOpenedSchemeTargetState: ({
      schemeTarget,
    }: {
      schemeTarget: string
    }) => {
      set((state) => {
        const prevTarget = state.lastOpenedSchemeTargetSlice.schemeTarget

        return {
          lastOpenedSchemeTargetSlice: {
            ...state.lastOpenedSchemeTargetSlice,
            schemeTarget:
              prevTarget === schemeTarget ? prevTarget : schemeTarget,
          },
        }
      })
    },
  },
})
