import * as css from './SafeAreaInset.css'

export function SafeAreaInsetTop(props: {
  addScrollToTopInset?: boolean
  isIOS?: boolean
  children?: React.ReactNode
}) {
  return (
    <div
      className={css.safeAreaInsetTop({
        addScrollToTopInsetToIOS: props.addScrollToTopInset && props.isIOS,
      })}
    >
      {props.children}
    </div>
  )
}

export function SafeAreaInsetBottom(props: {
  hidden?: boolean
  children?: React.ReactNode
}) {
  return (
    <div className={css.safeAreaInsetBottom({ hidden: props.hidden })}>
      {props.children}
    </div>
  )
}
