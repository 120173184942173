import { useMemo } from 'react'
import { usePriceWithCurrency, commaNumber } from '../_app'
import { TagGroupEllipsis } from '../tag-group'
import { TagEllipsis } from '../tag'
import {
  ADAPTIVE_IMAGE_FORMAT,
  ADAPTIVE_IMAGE_RESIZABLE_OPTION,
  AdaptiveImage,
} from '../adaptive-image'
import { BADGE_STYLE_TYPE_TO_PROPS } from './constants'
import * as css from './CardCommerce.css'
import { Badge } from '../badge'
import {
  IconHeartFill,
  IconHorizline2VerticalChatbubbleRectangularRightFill,
} from '@daangn/react-monochrome-icon'

type FlagType = {
  readonly label: string
  readonly value: string
}
interface CardCommerceProps {
  thumbnail: string
  fallbackThumbnail: string
  isSoldOut: boolean
  productName: string
  displayPrice: number
  discountRatio?: number
  storeName: string
  regionName: string
  flagList: readonly FlagType[]
  reviewCount: number
  watchCount: number
}
const CardCommerce = (props: CardCommerceProps) => {
  const priceWithCurrency = usePriceWithCurrency(props.displayPrice)

  const showDiscount = useMemo(
    () => !!props.discountRatio,
    [props.discountRatio]
  )

  const showReviewCount = useMemo(
    () => !!props.reviewCount,
    [props.reviewCount]
  )
  const reviewCountInComma = useMemo(
    () => commaNumber(props.reviewCount),
    [props.reviewCount]
  )

  const showWatchCount = useMemo(() => !!props.watchCount, [props.watchCount])
  const watchCountInComma = useMemo(
    () => commaNumber(props.watchCount),
    [props.watchCount]
  )
  const a11yLabel = useMemo(() => {
    const title = `${props.productName}`
    const price = showDiscount
      ? `${props.discountRatio}% ${props.displayPrice}원`
      : `${props.displayPrice}원`
    const store = `${props.storeName}`
    const regionName = `${props.regionName}`
    const userReaction = `후기 ${props.reviewCount} 관심 ${props.watchCount}`
    const isSoldOut = props.isSoldOut ? `품절` : ''
    const delivery = props.flagList.reduce((acc, { label }) => {
      return `${acc} ${label}`
    }, '')
    return `${isSoldOut} ${title} ${price} ${store} ${regionName} ${userReaction} ${delivery}`
  }, [
    showDiscount,
    props.productName,
    props.storeName,
    props.discountRatio,
    props.displayPrice,
    props.isSoldOut,
    props.flagList,
    props.watchCount,
    props.reviewCount,
    props.regionName,
  ])

  return (
    <div className={css.container} aria-label={a11yLabel}>
      <div className={css.imageContainer}>
        <AdaptiveImage
          className={css.image}
          src={props.thumbnail}
          fallbackSrc={props.fallbackThumbnail}
          options={[
            {
              size: '300x300',
              quality: 82,
              type: ADAPTIVE_IMAGE_RESIZABLE_OPTION.CROP,
              format: ADAPTIVE_IMAGE_FORMAT.WEBP,
            },
          ]}
        />
        {props.isSoldOut ? (
          <div className={css.soldOutOverlay}>
            <div className={css.soldOut}>품절</div>
          </div>
        ) : null}
      </div>
      <div className={css.main}>
        <div className={css.title}>{props.productName}</div>
        <div className={css.price}>
          {showDiscount ? (
            <span className={css.discount}>{props.discountRatio}%</span>
          ) : null}
          <span className={css.amount}>{priceWithCurrency}</span>
        </div>
        <div className={css.infoContainer}>
          <TagGroupEllipsis>
            <TagEllipsis>{props.storeName}</TagEllipsis>
            {props.regionName ? (
              <TagGroupEllipsis subGroup>
                <TagEllipsis>{props.regionName}</TagEllipsis>
              </TagGroupEllipsis>
            ) : null}
          </TagGroupEllipsis>
        </div>
        <div className={css.flags}>
          {props.flagList.map(({ label, value }) => (
            <Badge
              key={label}
              className={css.flag}
              shape="square"
              size="small"
              {...(BADGE_STYLE_TYPE_TO_PROPS[value] ?? {
                variant: 'basic',
                type: 'normal',
              })}
            >
              {label}
            </Badge>
          ))}
        </div>
        {showReviewCount || showWatchCount ? (
          <div className={css.userReactionCaptions}>
            {showReviewCount ? (
              <div className={css.caption}>
                <div className={css.captionIcon}>
                  <IconHorizline2VerticalChatbubbleRectangularRightFill
                    size={15}
                  />
                </div>
                {reviewCountInComma}
              </div>
            ) : null}
            {showWatchCount ? (
              <div className={css.caption}>
                <div className={css.captionIcon}>
                  <IconHeartFill size={15} />
                </div>
                {watchCountInComma}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CardCommerce
