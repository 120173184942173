export const numberFormat = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const makeNumberToKoreanSplitUnit = (number: number) => {
  const inputNumber = number > 0 ? number : 0
  const UNIT_WORDS = ['', '만', '억']
  const SPLIT_UNIT = 10_000
  const splitCount = UNIT_WORDS.length

  // splitUnit 단위로 숫자를 잘라서 배열에 저장 (10000이면 4자리)
  const resultArray = Array.from({ length: splitCount }).map((_, index) => {
    // math.pow는 제곱 (0, 1, 2 제곱)
    const rest = inputNumber % Math.pow(SPLIT_UNIT, index + 1)

    const unitResult = rest / Math.pow(SPLIT_UNIT, index)

    const floorUnit = Math.floor(unitResult)

    return floorUnit > 0 && floorUnit
  })

  const makeSplitUnitString = (numberArr: (number | false)[]) => {
    let resultString = ''

    for (let i = 0; i < numberArr.length; i++) {
      const val = numberArr[i]

      if (!val) {
        continue
      }

      const newString = String(numberFormat(val)) + UNIT_WORDS[i]
      resultString = newString + ' ' + resultString
    }

    return resultString.trim()
  }

  return makeSplitUnitString(resultArray)
    ? makeSplitUnitString(resultArray)
    : '0'
}
