import 'src/packages/commerce/CardCommerce.css.ts.vanilla.css?source=#H4sIAAAAAAAAA51WTW/iMBC991dEK1UqB6MECqVBe+K0p0p7Xa0qx54Et44d2Q6UXfW/r+N8khiWXVUUYZiZN2/em3i+w4ruZJ6DIvBaalDfARPDpNjhonrTr6/Hd1IsVmHw+y4IKNMFx6c4SDl8bO1BjlXGBDKyiANcGlmdvZXasPSEiBQGhKl/jEDQ7d3n3fysIqmrtEUiV4RILlUcHLB6QEgDUKQJ5oDcOcoUPqF1GM6qUqktgTT7BXEQzjfRYqUg3/pwYs4ygZiBXMcBsahADeArlu1NlaJLwJkAtIf6PArD+79jjznWBpE949S1Mco9TaAlpy+laRMsXNRZR08tHAMfBrkehugLTCkTWQfcvq/WyyYkweQ9U7IUFHkINdgw0jDafEi4jbBFij1Gq4bfQmpWNWmHm1i4pQGXWhojc9dT9eGjwutwJFJRUMgeVd8cGTX7lj3vWD0ojns7JFcbHSF5Zwa53lPGedvHrSku8P1yAGW10dK+dLRfZ2ssv5ak2aDNdT+tVjeDIz+RzjaORQ6pXyQpx1lnwsehsOrg+fLpguh9mdpEq1hI8zBQ7Mwn2Xm02bjc41SFYvZ/k2vtQs8Ns1zdj7eDzda5ayDyyFsA53YUnTOeemccmwqJneU0zDJAhoGbS4FXnO8ZP+RY9Cqzzdvg03WN3hI+WSgWJ7Y0qhb+s3/ncjB2ASBdYOJch8J5uBguCgpEKlzLTUgBPeXmxKE6UznmF5oda/3ZrYIxVttDBrsxYFzzbXFaSu1f+0S4ySLXLTgiERfVnukiZqMEtbaIPNSLcvBNb8RVeF+9pmKIGkBVdMrlMQ72jFIQ5z5WwC3Fh2Yhur2nMGWlHpryVto8v2q/TBynNzEokzcglQ7NsPd/wtZV/aEV+frly89zCdZqGkfmtps2kPg1664AlCkgNXt2jGUutp1aosvk5nYyTfue/WiY4R1qenkVTVZO45V+yMA5KzTTTrDVI8Q5zNnlqHBxSRH/baHm/vCN9HcI8JM3uVD1V4Ab7zXRtfU2hvvod7xI5US5qeeB1Nb6/AM5ytWKXQoAAA==';
export var amount = 'CardCommerce_amount__wkcp257';
export var caption = 'CardCommerce_caption__wkcp251';
export var captionIcon = 'CardCommerce_captionIcon__wkcp25e';
export var container = 'CardCommerce_container__wkcp259';
export var discount = 'CardCommerce_discount__wkcp258';
export var flag = 'CardCommerce_flag__wkcp255';
export var flags = 'CardCommerce_flags__wkcp254';
export var image = 'CardCommerce_image__wkcp25b';
export var imageContainer = 'CardCommerce_imageContainer__wkcp25a';
export var infoContainer = 'CardCommerce_infoContainer__wkcp25f';
export var main = 'CardCommerce_main__wkcp25c';
export var price = 'CardCommerce_price__wkcp256';
export var soldOut = 'CardCommerce_soldOut__wkcp252';
export var soldOutOverlay = 'CardCommerce_soldOutOverlay__wkcp253';
export var title = 'CardCommerce_title__wkcp25d';
export var userReactionCaptions = 'CardCommerce_userReactionCaptions__wkcp250';