import type { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'
import ExperimentError from '../../error/class/ExperimentError'
import {
  SEARCH_EXPERIMENT_CUSTOM_TRIGGER_FLAG_HEADER_KEY,
  SEARCH_EXPERIMENT_SEGMENT_HEADER_KEY,
} from '../constants/experiment'

export const getExperimentPayloadFromHeader = (
  headers?: AxiosResponseHeaders | RawAxiosResponseHeaders
) => {
  const isExperimentTriggered =
    headers?.[SEARCH_EXPERIMENT_CUSTOM_TRIGGER_FLAG_HEADER_KEY] === 'true' ||
    false

  const experimentSegments = (() => {
    const segmentsJSONString =
      headers?.[SEARCH_EXPERIMENT_SEGMENT_HEADER_KEY] ?? '[]'
    try {
      return JSON.parse(segmentsJSONString)
    } catch (e) {
      throw new ExperimentError(
        `header value(${SEARCH_EXPERIMENT_SEGMENT_HEADER_KEY}) format error: ${segmentsJSONString}`
      )
    }
  })()

  return {
    isExperimentTriggered,
    experimentSegments,
  }
}
