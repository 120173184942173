import { SafeAreaInsetTop } from '../_app/components/safe-area-inset/SafeAreaInset'
import { clientEnvironment } from '../bridge/utils/bridgeMaker'
import SearchInputNavbarSkeleton from '../_app/components/skeleton/SearchInputNavbarSkeleton'

interface PageHomeSearchSkeletonProps {
  query: string
  onBackClick: () => void
}

const PageHomeSearchSkeleton = (props: PageHomeSearchSkeletonProps) => {
  // iOS 초기 1회 덜그덕거리는 렌더링 수정
  if (
    clientEnvironment.isIOS &&
    !localStorage.getItem('env(safe-area-inset-top)')
  ) {
    return null
  }

  return (
    <>
      <SafeAreaInsetTop />
      <SearchInputNavbarSkeleton
        platform={clientEnvironment.platform}
        inputValue={props.query}
        onBackClick={props.onBackClick}
      />
    </>
  )
}

export default PageHomeSearchSkeleton
