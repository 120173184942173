import type { Plugin } from 'plantae'

export default function plantaeUserAgentPlugin({
  userAgent,
}: {
  userAgent: string
}): Plugin {
  return {
    name: 'plugin-user-agent',
    hooks: {
      beforeRequest: async (req) => {
        req.headers.set('x-user-agent', userAgent)

        return req
      },
    },
  }
}
