import type {
  ChangeEventHandler,
  FormEventHandler,
  FocusEventHandler,
} from 'react'
import { forwardRef } from 'react'
import SearchInputCategoryLabel from './SearchInputCategoryLabel'
import {
  BackIconContainer,
  CloseButton,
  CloseButtonContainer,
  Container,
  Input,
  InputArea,
  InputAreaTreatment,
  InputBox,
  InputCancel,
  InputCancelTreatment,
  PageBackContainer,
} from './SearchInputNavbar.css'
import { vars } from '@seed-design/design-token'
import {
  IconChevronLeftLine,
  IconXmarkCircleFill,
} from '@daangn/react-monochrome-icon'

type PlatformType = 'Cupertino' | 'Android' | 'Web'

interface SearchInputNavbarProps {
  platform?: PlatformType
  inputId?: string
  inputValue: string
  onBackClick: () => void
  onInputChange: (value: string) => void
  onCancelClick: () => void
  onSubmit: () => void
  placeholder?: string
  categoryName?: string
  onRemoveCategory?: () => void
  onCloseClick?: () => void
  onInputFocus?: FocusEventHandler<HTMLInputElement>
  onInputBlur?: FocusEventHandler<HTMLInputElement>
  hideRemoveIcon?: boolean
}

const defaultPlatform = (platform?: PlatformType) => {
  if (!platform || platform === 'Web') {
    return 'Android'
  }

  return platform
}

const SearchInputNavbar = forwardRef<HTMLInputElement, SearchInputNavbarProps>(
  (props, ref) => {
    const platform = defaultPlatform(props.platform)

    const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      props.onInputChange(e.target.value)
    }

    const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
      e.preventDefault()
      props.onSubmit()
    }

    return (
      <div className={Container}>
        <div
          className={PageBackContainer}
          role="button"
          aria-label={'뒤로가기'}
        >
          <div
            className={BackIconContainer({ platform })}
            onClick={props.onBackClick}
          >
            <IconChevronLeftLine size={24} color={vars.$scale.color.gray900} />
          </div>
        </div>
        <div
          className={
            props.onCloseClick
              ? InputAreaTreatment({ platform })
              : InputArea({ platform })
          }
        >
          <form
            className={InputBox}
            autoComplete="off"
            noValidate
            onSubmit={onSubmit}
          >
            {props.categoryName ? (
              <SearchInputCategoryLabel
                name={props.categoryName}
                onRemove={(e) => {
                  e.stopPropagation()
                  props.onRemoveCategory?.()
                }}
              />
            ) : null}
            <input
              autoComplete="off"
              className={Input({ platform })}
              id={props.inputId}
              ref={ref}
              value={props.inputValue}
              name="searchInput"
              enterKeyHint="search"
              type="search"
              placeholder={props.placeholder}
              onChange={onInputChange}
              onFocusCapture={props.onInputFocus}
              onBlurCapture={props.onInputBlur}
            />
          </form>
          {props.inputValue.trim().length > 0 && !props.hideRemoveIcon ? (
            <div
              className={
                props.onCloseClick
                  ? InputCancelTreatment({ platform })
                  : InputCancel({ platform })
              }
              role="button"
              aria-label={'텍스트 지우기'}
              onClick={props.onCancelClick}
            >
              <IconXmarkCircleFill
                size={18}
                color={vars.$scale.color.gray600}
              />
            </div>
          ) : null}
        </div>
        {props.onCloseClick ? (
          <div className={CloseButtonContainer}>
            <button
              type="button"
              className={CloseButton({ platform })}
              onClick={props.onCloseClick}
            >
              닫기
            </button>
          </div>
        ) : null}
      </div>
    )
  }
)

export default SearchInputNavbar
