import type { MouseEvent, ReactNode } from 'react'
import * as css from './RecentSearch.css'

interface RecentSearchProps {
  children: ReactNode
  onRemoveAllClick: (e: MouseEvent<HTMLSpanElement>) => void
}

const RecentSearch = (props: RecentSearchProps) => {
  return (
    <article className={css.container}>
      <header className={css.header}>
        <h2 className={css.title} a11y-label="최근 검색">
          최근 검색
        </h2>
        <div
          className={css.removeAll}
          role="button"
          onClick={props.onRemoveAllClick}
        >
          전체 삭제
        </div>
      </header>
      <ol className={css.body}>{props.children}</ol>
    </article>
  )
}
export default RecentSearch
