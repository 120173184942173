/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */
export function makeSearchBridge({ driver, }) {
    return {
        driver,
        /**
         * 클릭된 검색 쿼리(키워드)를 Native로 전달해요
         */
        sendHomeKeywordClicked(req) {
            return driver.onQueried("REQ.SEARCH.HOME.KEYWORD_CLICKED", req);
        },
        /**
         * 검색 input blur(focus out) 처리해요
         */
        blurSearchInput(req) {
            return driver.onQueried("REQ.SEARCH.INPUT_FOCUS_OUT", req);
        },
    };
}
